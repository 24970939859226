import { nlPostalCodeRegex as g, beMunicipalityRegex as C } from "@intergamma/regex";
function r(t) {
  return (e, s, o) => e != null ? t(e, s, o) : "";
}
const D = r((t) => t.toLowerCase()), $ = r((t) => t.toUpperCase()), b = r(
  (t) => t.substring(0, 1).toUpperCase() + t.substring(1).toLowerCase()
), n = r((t) => `00${t}`.slice(-2)), m = r((t, e, s) => {
  let o;
  if (Array.isArray(t)) {
    const [i, c, p] = t;
    o = new Date(Date.UTC(i, c - 1, p, 12, 0, 0));
  } else
    o = new Date(t);
  if (!s || !e)
    return "";
  const a = s.getFixedT(e);
  return [
    n(o.getDate()),
    n(o.getMonth() + 1),
    o.getFullYear()
  ].join(a("dateSeparator"));
}), u = r((t) => {
  const e = Number.isNaN(Date.parse(t)) ? /* @__PURE__ */ new Date(`1970-01-01T${t}`) : new Date(t);
  return [e.getHours(), n(e.getMinutes())].join(":");
}), w = r((t) => {
  const e = Number.isNaN(Date.parse(t)) ? /* @__PURE__ */ new Date(`1970-01-01T${t}`) : new Date(t);
  return [n(e.getHours()), n(e.getMinutes())].join(":");
}), f = r(
  (t, e, s) => `${m(t, e, s)} ${u(t)}`
), N = r((t) => {
  if (typeof t != "string")
    return t;
  const e = t.replace(/\s/g, "");
  if (g.test(e)) {
    const s = e.substring(0, 4), o = e.substring(4, 6);
    return `${s} ${o.toUpperCase()}`;
  }
  return t.toUpperCase();
}), h = r((t) => {
  const e = t.match(C);
  return e && e.length === 3 ? `${e[1]} ${e[2].toUpperCase()}` : t.toUpperCase();
}), U = r(
  (t) => t.replace(/[^0-9]+/g, "").replace(/([0-9]{4})/g, "$1 ").replace(/^\s+|\s+$/g, "")
  // trim whitespace
), y = r(
  (t) => t && t.replace(/[^0-9 +-]/g, "").replace(/\s+/g, " ")
), l = r((t) => t.replace(/^\s+|\s+$/g, ""));
export {
  m as toDateString,
  f as toDatetimeString,
  U as toGiftcardNumber,
  w as toLeadZeroTimeString,
  D as toLowerCase,
  h as toMunicipality,
  y as toPhoneNumber,
  N as toPostalCode,
  u as toTimeString,
  $ as toUpperCase,
  l as trim,
  n as twoDigits,
  b as ucFirst
};
