import {
  Button,
  CheckboxField,
  ExclamationMark,
  Feedback,
  Snackbar,
} from "@intergamma/ui";
import { isMobileApp } from "@intergamma/common";
import { dispatchMobileAppEvent } from "@intergamma/mobile-app";

import { Trans, useTranslation } from "react-i18next";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { Controller, useForm } from "react-hook-form";

import { getLanguage } from "~/helpers/getLanguage";

import { useMyAccountConfig } from "~/config/MyAccountConfigContext";

interface RemoveAccountFormProps {
  onSubmit: () => void;
  onClose: () => void;
  errorMessage: null | string;
  isLoading: boolean;
}

export function RemoveAccountForm({
  errorMessage,
  isLoading,
  onSubmit,
  onClose,
}: RemoveAccountFormProps) {
  const { t, i18n } = useTranslation(["profile", "urls", "common"]);
  const {
    urls,
    baseConfig: { country, brand },
  } = useMyAccountConfig();

  const form = useForm({
    defaultValues: {
      confirmation: false,
    },
    resolver: zodResolver(z.object({ confirmation: z.literal(true) })),
  });

  const {
    control,
    watch,
    formState: { errors },
  } = form;

  const confirmation = watch("confirmation");

  const submit = form.handleSubmit((values) => {
    if (values.confirmation) {
      onSubmit();
    }
  });

  const openChat = () => {
    if (isMobileApp) {
      dispatchMobileAppEvent({
        app: "myaccount",
        type: "IG_OPEN_CHAT",
      });
    } else {
      window.fcWidget?.open();
    }
  };

  const phoneNumber = brand === "gamma" ? "0880108610" : "0880108620";

  if (errorMessage === "424") {
    const language = getLanguage(i18n.language) === "fr" ? "fr" : "nl";
    const currentUrls = urls[language]!;

    return (
      <div className="flex flex-col gap-4">
        <p>
          <Trans
            t={t}
            ns="profile"
            i18nKey="bodyAccountRemovalNotPossible"
            defaultValue="We kunnen je account op dit moment niet verwijderen in verband met een openstaande bestelling of verhuurcontract.
Neem hierover indien nodig contact op met je GAMMA bouwmarkt of de <cs-link>klantenservice</cs-link>."
            components={{
              "cs-link": (
                <a href={currentUrls.customerService} className="underline">
                  klantenservice
                </a>
              ),
            }}
          />
        </p>
        <p>
          <Trans
            t={t}
            ns="profile"
            i18nKey="contactUs"
            defaultValue="Bel ons: <phone-link>088-010 8610</phone-link>, of chat met ons en krijg direct antwoord."
            components={{
              "phone-link": (
                <a href={`tel:${phoneNumber}`} className="underline">
                  tel
                </a>
              ),
            }}
          />
        </p>
        {country === "nl" && (
          <div className="flex flex-row text-functional-primary-300">
            <button type="button" className="flex gap-2" onClick={openChat}>
              <Feedback /> {t("common:openChat")}
            </button>
          </div>
        )}
        <div className="flex flex-row justify-end">
          <Button type="button" onClick={onClose}>
            {t("common:buttonClose")}
          </Button>
        </div>
      </div>
    );
  }

  return (
    <form className="flex flex-col gap-4" onSubmit={submit}>
      <p>{t("profile:bodyRemoveAccount")}</p>
      <ul>
        {t("profile:removalReminders", { returnObjects: true }).map(
          (reminder) => (
            <li key={reminder} className="ml-6 list-disc pl-2">
              {reminder}
            </li>
          ),
        )}
      </ul>
      <Controller
        name="confirmation"
        control={control}
        render={({ field }) => (
          <CheckboxField
            ref={field.ref}
            name={field.name}
            checked={field.value}
            defaultChecked={false}
            label={t("profile:removeAccountCheckLabel")}
            onCheckedChange={(checked) => {
              if (checked === "indeterminate") {
                return;
              }

              field.onChange(checked);
            }}
          />
        )}
      />
      {errors.confirmation && !confirmation && (
        <Snackbar variant="error" icon={<ExclamationMark />}>
          {t("profile:errorNoCheckboxRemoveAccount")}
        </Snackbar>
      )}
      <div className="flex flex-row justify-end gap-4">
        <Button type="button" variant="secondary" onClick={onClose}>
          {t("common:cancel")}
        </Button>
        <Button type="submit" loading={isLoading}>
          {t("common:delete")}
        </Button>
      </div>
    </form>
  );
}
