import { memo } from "react";

import { Outlet } from "react-router-dom";

import { Container } from "~/components/design-system/Container";

function RouteLayoutView() {
  return (
    <div className="bg-ignew-neutral-0">
      <Container>
        <Outlet />
      </Container>
    </div>
  );
}

export const DSLayout = memo(RouteLayoutView);
