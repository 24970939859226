// TODO: Move redirects to Hosting instead of client side redirects
export const gammaNlBeRedirects = {
  "/mijn-gegevens": "/nl/mijn-gegevens/persoonlijke-gegevens",
  "/profile": "/nl/mijn-gegevens/persoonlijke-gegevens",
  "/personal-information": "/nl/mijn-gegevens/persoonlijke-gegevens",
  "/preferences": "/nl/mijn-gegevens/nieuwsbrief",
  "/change-email": "/nl/mijn-gegevens/e-mailadres-wijzigen",
  "/change-password": "/nl/mijn-gegevens/wachtwoord-wijzigen",
  "/loyaltycards": "/nl/mijn-gegevens/gamma-plus-kaart",
  "/orders": "/nl/mijn-bestellingen",
  "/remove-account": "/nl/verzoek-tot-verwijderen-account",
  "/loyaltyMutations": "/nl/loyalty-transactie-overzicht",
  "/orderstatus": "/nl/bestelstatus-opvragen",
  "/logout": "/nl/uitloggen",
  "/register": "/nl/account-aanmaken",
  "/forgot-password": "/nl/nieuw-wachtwoord-aanvragen",
  "/password-recovery": "/nl/nieuw-wachtwoord-aanvragen/verificatie",
  "/register-loyalty-card": "/nl/gamma-plus-kaart/registreren",
  "/register-loyalty-card/activate": "/nl/gamma-plus-kaart/activeer",
  "/giftcard": "/nl/cadeaukaart",
  "/request-return": "/nl/aanmelden-retour",
  "/newsletter/subscribe": "/nl/aanmelden-nieuwsbrief",
  "/service-and-contact/contact": "/nl/service-en-contact/contact",
  "/success/register": "/nl/account-aanmaken-bevestigd",
  "/service-en-contact": "/nl/service-en-contact/contact",
  "/login": "/nl/inloggen",
  "/confirm-email": "/nl/e-mailadres-bevestigen",
  "/profile/edit/email": "/nl/mijn-gegevens/e-mailadres-wijzigen",
  "/profile/edit/newsletters": "/nl/mijn-gegevens/nieuwsbrief",
  "/profile/edit/personal_info": "/nl/mijn-gegevens/persoonlijke-gegevens",
  "/profile/edit/password": "/nl/mijn-gegevens/wachtwoord-wijzigen",
  "/profile/edit/loyaltycards": "/nl/mijn-gegevens/gamma-plus-kaart",
  "/password_recovery": "/nl/nieuw-wachtwoord-aanvragen/verificatie",
  "/signup": "/nl/account-aanmaken",
  "/order": "/nl/bestelstatus-opvragen",
  "/return": "/nl/aanmelden-retour",
  "/giftcard/showBalance": "/nl/cadeaupas",
  "/verificatie-nieuwsbrief-aanvraag": "/nl/verificatie/newsletter",
  "/gamma-plus-kaart": "/nl/mijn-gegevens/gamma-plus-kaart",
  "/purchases": "/nl/mijn-aankopen",
};

export const gammaFrBeRedirects = {
  "/mes-donnees": "/fr/mes-donnees/mes-donnes-personnelles",
  "/profile": "/fr/mes-donnees/mes-donnes-personnelles",
  "/personal-information": "/fr/mes-donnees/mes-donnes-personnelles",
  "/preferences": "/fr/mes-donnees/lettre-info",
  "/change-email": "/fr/mes-donnees/changer-ladresse-mail",
  "/change-password": "/fr/mes-donnees/changer-le-mot-de-passe",
  "/loyaltycards": "/fr/mes-donnees/carte-gamma-plus",
  "/orders": "/fr/mes-commandes",
  "/remove-account": "/fr/demande-de-suppression-de-mon-compte",
  "/loyaltyMutations": "/fr/GAMMAplus-liste-transactions",
  "/fr/loyalty-transactie-overzicht": "/fr/GAMMAplus-liste-transactions",
  "/orderstatus": "/fr/etat-de-la-commande",
  "/logout": "/fr/se-deconnecter",
  "/register": "/fr/creer-un-compte",
  "/forgot-password": "/fr/demander-nouveau-mot-de-passe",
  "/password-recovery": "/fr/demander-nouveau-mot-de-passe/verification",
  "/register-loyalty-card": "/fr/carte-gamma-plus/registre",
  "/register-loyalty-card/activate": "/fr/carte-gamma-plus/activer",
  "/giftcard": "/fr/carte-cadeau",
  "/request-return": "/fr/inscription-retour",
  "/newsletter/subscribe": "/fr/inscrire-lettre-info",
  "/service-and-contact/contact": "/fr/service-en-contact/contact",
  "/success/register": "/fr/creer-un-compte-confirme",
  "/service-en-contact": "/fr/service-en-contact/contact",
  "/login": "/fr/se-connecter",
  "/confirm-email": "/fr/confirmer-ladresse-mail",
  "/profile/edit/email": "/fr/mes-donnees/changer-ladresse-mail",
  "/profile/edit/newsletters": "/fr/mes-donnees/lettre-info",
  "/profile/edit/personal_info": "/fr/mes-donnees/mes-donnes-personnelles",
  "/profile/edit/password": "/fr/mes-donnees/changer-le-mot-de-passe",
  "/profile/edit/loyaltycards": "/fr/mes-donnees/carte-gamma-plus",
  "/password_recovery": "/fr/demander-nouveau-mot-de-passe/verification",
  "/signup": "/fr/creer-un-compte",
  "/order": "/fr/etat-de-la-commande",
  "/return": "/fr/inscription-retour",
  "/giftcard/showBalance": "/fr/carte-cadeau",
  "/verification-lettre-info-demande": "/fr/verification/newsletter",
  "/carte-gamma-plus": "/fr/mes-donnees/carte-gamma-plus",
  "/purchases": "/fr/mijn-aankopen",
};
