import { jsxs as h, Fragment as re, jsx as e } from "react/jsx-runtime";
import { createContext as Ke, useContext as ze, useMemo as se, useCallback as B, useState as _, useEffect as I, useId as Qe, useRef as ce } from "react";
import { useTranslation as E, Trans as Re } from "react-i18next";
import { useConfig as Y } from "@intergamma/config";
import { useActiveVariant as Oe } from "@intergamma/experiments/hooks";
import { PrivacyMask as J } from "@intergamma/privacy-mask";
import { dispatchAdobeEvent as N } from "@intergamma/adobe-tracking";
import { calculatePasswordStrength as Fe, useStrengthText as Ue, TextField as de, PasswordField as ke, CheckboxField as Ee, VerifyCodeField as Te } from "@intergamma/ui/form";
import { Button as X } from "@intergamma/ui/button";
import { Edit as pe, Correct as Ie } from "@intergamma/icons";
import { ExclamationMark as Pe, CheckMark as Je } from "@intergamma/ui/icons";
import { Snackbar as ue } from "@intergamma/ui/snackbar";
import { cn as D } from "@intergamma/common/cn";
import { useSearchParams as Xe, useLocation as Ze, Navigate as et, useNavigate as tt } from "react-router-dom";
import { useForm as he, Controller as fe } from "react-hook-form";
import { zodResolver as we } from "@hookform/resolvers/zod";
import { datadogRum as ne } from "@datadog/browser-rum";
import { isAxiosError as ve } from "axios";
import { useMutation as me, useQuery as rt } from "@tanstack/react-query";
import { c as nt, a as st } from "./index-C2EW4nef.js";
import { l as ge } from "./datadog-logging-Ds9IeYxC.js";
import { z as U } from "zod";
import { isKiosk as ot } from "@intergamma/kiosk-util";
import it from "@intergamma/mailcheck";
import { toast as ye } from "react-toastify";
import { signIn as at } from "aws-amplify/auth";
import { LoyaltyCard as lt } from "@intergamma/loyalty-card";
import { getMobileOperatingSystem as ct, MobileOperatingSystem as dt } from "@intergamma/common";
var A = /* @__PURE__ */ ((r) => (r[r.LOGIN = 0] = "LOGIN", r[r.SIGN_UP = 1] = "SIGN_UP", r[r.VERIFY = 2] = "VERIFY", r[r.FORGOT_PASSWORD = 3] = "FORGOT_PASSWORD", r))(A || {}), te = /* @__PURE__ */ ((r) => (r[r.LOGIN = 0] = "LOGIN", r[r.RESEND_VERIFICATION = 1] = "RESEND_VERIFICATION", r[r.RESEND_SUCCESS = 2] = "RESEND_SUCCESS", r))(te || {}), qe = /* @__PURE__ */ ((r) => (r.CODE = "CODE", r.HASH = "HASH", r))(qe || {});
const ut = {
  signUpRequestBody: {
    email: "",
    password: "",
    locale: "nl",
    optIn: !1,
    verificationMethod: "CODE",
    passwordless: !1
  },
  setSignUpRequestBody: () => {
  },
  setSignUpResponse: () => {
  },
  loginAfterSignUp: !1,
  setLoginAfterSignUp: () => {
  },
  setVerificationError: () => {
  },
  setFlow: () => {
  }
}, Me = Ke(ut), mt = Me.Provider, ae = () => ze(Me);
function le(r) {
  const n = nt(`${r}/api`, {
    withCredentials: !0
  });
  return {
    getAccountState(t) {
      return n.get(
        `/account/?email=${encodeURIComponent(t)}`
      );
    },
    resendVerification(t) {
      return n.post(
        "/signup/resendsignupverification",
        t
      );
    },
    signUp(t) {
      return n.post("/signup", t);
    },
    verify(t, l, a) {
      return n.post(`/verify/${t}?hash=${l}`, {
        hash: l,
        customerId: a
      });
    },
    passwordReset(t) {
      return n.post("/password-reset", t);
    },
    verifyPasswordReset(t) {
      return n.post("/verify/password-reset", t);
    }
  };
}
const Ae = (r) => {
  switch (r) {
    case "nl_BE":
    case "nl-BE":
      return "nl_BE";
    case "fr_BE":
    case "fr-BE":
      return "fr_BE";
    default:
      return "nl";
  }
};
class T extends Error {
  constructor(n) {
    super(n), this.name = "TranslatedError";
  }
}
function gt({ onSuccess: r }) {
  const { MYACCOUNT_URL: n } = Y(), { t, i18n: l } = E(["register", "errors"]), a = se(
    () => le(n),
    [n]
  ), i = B(() => {
    ne.addAction("signup");
  }, []);
  function o({ password: s, email: c, optIn: u, passwordless: d }) {
    return a.signUp({
      email: c,
      password: s,
      locale: Ae(l.language),
      optIn: u,
      verificationMethod: "CODE",
      passwordless: d
    }).catch((m) => {
      var p, g, R;
      if (ve(m) && ((g = (p = m.response) == null ? void 0 : p.data) != null && g.status)) {
        const k = ((R = m.response) == null ? void 0 : R.data).status;
        if (k === "UserSignUpInvalidEmail")
          throw new T(t("register:invalidEmail"));
        if (k === "UserSignUpPasswordInvalid")
          throw new T(t("register:invalidSignupPassword"));
        if (k === "UserSignUpAlreadyExists")
          throw new T(t("register:userAlreadyExists"));
      }
      throw ge.error(
        d ? "Passwordless signup" : "Signup",
        {
          details: "Failed"
        },
        m
      ), new T(
        `${t("errors:somethingWentWrong")} ${t(
          "errors:ContactCustomerSupport"
        )}`
      );
    });
  }
  return me({
    mutationFn: o,
    throwOnError: !1,
    onSuccess: (s, c) => {
      i(), N({
        type: "sign_up_interaction",
        data: {
          user_selected_element: "Sign_up verification",
          user_message_value: `Commercial opt-in: ${c.optIn}`,
          user_selected_value: `Verification method: ${c.passwordless ? "passwordless" : "email"}`
        }
      }), r(s, c);
    },
    onError: (s) => {
      N({
        type: "sign_up_error",
        data: {
          error: s == null ? void 0 : s.message
        }
      });
    }
  });
}
const $e = (r, n, t) => ({
  gamma: {
    nl: {
      loyaltyProgram: `${t}/voordeelpas`,
      requirementsLoyalty: `${t}/klantenservice/algemene-voorwaarden/voordeelpas`
    },
    "nl-BE": {
      loyaltyProgram: `${t}/nl/klanteninformatie/gammapluskaart`,
      requirementsLoyalty: `${t}/nl/klanteninformatie/gammapluskaart?hp-gammaplus`
    },
    "fr-BE": {
      loyaltyProgram: `${t}/fr/infos-consommateur/carte-gammaplus`,
      requirementsLoyalty: `${t}/fr/infos-consommateur/carte-gammaplus`
    }
  },
  karwei: {
    nl: {
      loyaltyProgram: `${t}/clubkarwei`,
      requirementsLoyalty: `${t}/klantenservice/voorwaarden-veiligheid/algemene-voorwaarden/club-karwei`
    },
    karwei_nl: {
      loyaltyProgram: `${t}/clubkarwei`,
      requirementsLoyalty: `${t}/klantenservice/voorwaarden-veiligheid/algemene-voorwaarden/club-karwei`
    }
  }
})[r][n], ft = "account";
function De() {
  const { t: r } = E(["register", "errors"]), { MYACCOUNT_URL: n } = Y(), t = se(
    () => le(n),
    [n]
  ), [l, a] = _(void 0), i = B(async () => l ? t.getAccountState(l).catch((d) => {
    throw N({
      type: "login_email_check_error",
      data: {
        error: (d == null ? void 0 : d.message) || ""
      }
    }), new T(
      `${r("errors:somethingWentWrong")} ${r(
        "errors:ContactCustomerSupport"
      )}`
    );
  }) : null, [l, t, r]), { data: o, isFetching: s, refetch: c, error: u } = rt({
    queryKey: [ft, l],
    queryFn: i,
    throwOnError: !1
  });
  return {
    accountState: o,
    isFetching: s,
    error: u,
    refetch: c,
    getAccountState: (d) => {
      a(d);
    }
  };
}
function pt({
  isPasswordless: r
}) {
  const { t: n } = E(["register"]);
  return U.object({
    email: U.string().min(1, n("register:emailRequired")).email(n("register:emailInvalid")),
    ...r ? {} : {
      password: U.string().min(8, n("register:minPasswordLength")).max(50, n("register:maxPasswordLength"))
    },
    requirements: U.literal(!0, {
      errorMap: () => ({ message: n("register:requirementsError") })
    }),
    optIn: U.boolean()
  });
}
function ht({ setShowLoyaltyBenefits: r }) {
  var ie, K;
  const { t: n, i18n: t } = E([
    "common",
    "register",
    "errors",
    "ig-form",
    "urls",
    "login-form"
  ]), l = Oe("abtest_drl_2343-drl_1"), [a] = Xe(), i = a.get("method") === "passwordless", { signUpRequestBody: o, setSignUpRequestBody: s, setFlow: c } = ae(), u = pt({ isPasswordless: i }), {
    mutate: d,
    error: m,
    isPending: p
  } = gt({
    onSuccess: (w, M) => {
      s((f) => ({ ...f, ...M })), c({
        flow: A.VERIFY,
        verificationData: {
          customerId: w.customerId,
          control: w.control,
          email: M.email
        }
      }), r == null || r(!0), i && ge.info("Passwordless signup", {
        details: "Successful"
      });
    }
  }), [g, R] = _(null), { brand: y, SHOPFRONT_URL: k } = Y(), { getAccountState: H, accountState: V, isFetching: $ } = De(), [b, j] = _(null), P = $e(y, t.language, k), G = (P == null ? void 0 : P.requirementsLoyalty) ?? "/", q = (P == null ? void 0 : P.loyaltyProgram) ?? "/", {
    email: C,
    password: O,
    optIn: W
  } = o, L = he({
    defaultValues: {
      email: C,
      password: O,
      requirements: !1,
      optIn: W
    },
    resolver: we(u)
  }), Z = L.watch("password"), oe = se(
    () => Fe(Z),
    [Z]
  ), S = Ue(oe);
  I(() => {
    m != null && m.message && j(m.message);
  }, [m]);
  const ee = L.handleSubmit(
    (w) => {
      H(w.email), R({
        ...w,
        locale: "nl",
        // FIXME: get locale from somewhere?
        verificationMethod: "CODE",
        password: i ? window.crypto.randomUUID() : w.password,
        passwordless: i
      });
    },
    (w) => {
      for (const { message: M } of Object.values(w))
        N({
          type: "sign_up_error",
          data: {
            error: M
          }
        });
    }
  );
  return I(() => {
    V && !$ && (!V.hasAccount && g ? d({
      email: g.email,
      password: g.password,
      optIn: g.optIn,
      passwordless: g.passwordless
    }) : j(n("register:userAlreadyExists")));
  }, [
    V,
    $,
    n,
    g,
    d,
    s
  ]), I(() => {
    N({
      type: "sign_up_interaction",
      data: {
        user_selected_element: "Sign-up pop-up form"
      }
    });
  }, []), /* @__PURE__ */ h(re, { children: [
    /* @__PURE__ */ e("h4", { className: "mb-4 text-200 font-bold", children: n("login-form:buttonLabelNewAccount") }),
    /* @__PURE__ */ h(
      "form",
      {
        noValidate: !0,
        onSubmit: (w) => (w.stopPropagation(), ee(w)),
        className: "flex flex-col gap-4",
        children: [
          /* @__PURE__ */ e("div", { children: /* @__PURE__ */ e(J, { children: /* @__PURE__ */ e("div", { className: "relative", children: /* @__PURE__ */ e(
            de,
            {
              type: "email",
              label: n("common:EmailAddress"),
              error: (ie = L.formState.errors.email) == null ? void 0 : ie.message,
              autoCapitalize: "off",
              autoComplete: "email",
              disabled: !0,
              icon: /* @__PURE__ */ e(
                "button",
                {
                  className: D(
                    "bg-transparent border-none p-0 cursor-pointer text-2xl gamma:rounded-md",
                    "outline-none ring-offset-1 focus-visible:ring-3 focus-visible:ring-ignew-functional-focus-400"
                  ),
                  onClick: () => {
                    N({
                      type: "login_interaction",
                      data: {
                        user_selected_element: "Sign-up pop-up change e-mail link click"
                      }
                    }), c({ flow: A.LOGIN });
                  },
                  type: "button",
                  children: /* @__PURE__ */ e(pe, {})
                }
              ),
              ...L.register("email")
            }
          ) }) }) }),
          !i && /* @__PURE__ */ e("div", { children: /* @__PURE__ */ e(J, { children: /* @__PURE__ */ e(
            ke,
            {
              autoComplete: "new-password",
              autoFocus: !0,
              label: n("login-form:Password"),
              error: (K = L.formState.errors.password) == null ? void 0 : K.message,
              passwordStrength: oe,
              description: S,
              ...L.register("password")
            }
          ) }) }),
          /* @__PURE__ */ e("div", { children: /* @__PURE__ */ e(
            fe,
            {
              control: L.control,
              name: "requirements",
              defaultValue: !1,
              render: ({ field: w, fieldState: M }) => {
                var f;
                return /* @__PURE__ */ e(
                  Ee,
                  {
                    ref: w.ref,
                    name: w.name,
                    checked: w.value,
                    "data-testid": "requirements-checkbox",
                    onCheckedChange: (v) => {
                      v !== "indeterminate" && w.onChange(v);
                    },
                    error: (f = M.error) == null ? void 0 : f.message,
                    label: /* @__PURE__ */ e(
                      Re,
                      {
                        ns: "register",
                        i18nKey: "requirements",
                        defaultValue: "Met het aanmaken van een account, ga ik akkoord met de <terms-link>voorwaarden</terms-link> en profiteer ik van alle gratis voordelen van de <loyalty-link>{{loyaltyCardName}}</loyalty-link>",
                        components: {
                          "terms-link": /* @__PURE__ */ e(
                            "a",
                            {
                              target: "_blank",
                              href: G,
                              rel: "noreferrer",
                              className: "underline",
                              children: "voorwaarden"
                            }
                          ),
                          "loyalty-link": /* @__PURE__ */ e(
                            "a",
                            {
                              target: "_blank",
                              href: q,
                              rel: "noreferrer",
                              className: "underline",
                              children: "pas"
                            }
                          )
                        }
                      }
                    )
                  }
                );
              }
            }
          ) }),
          /* @__PURE__ */ e("div", { children: /* @__PURE__ */ e(
            fe,
            {
              control: L.control,
              name: "optIn",
              defaultValue: !1,
              render: ({ field: w, fieldState: M }) => {
                var f;
                return /* @__PURE__ */ e(
                  Ee,
                  {
                    ref: w.ref,
                    name: w.name,
                    checked: w.value,
                    onCheckedChange: (v) => {
                      v !== "indeterminate" && w.onChange(v);
                    },
                    error: (f = M.error) == null ? void 0 : f.message,
                    label: n("register:optinNewsletter", {
                      context: l === "B-new" ? "FiveHundred" : void 0
                    })
                  }
                );
              }
            }
          ) }),
          b && /* @__PURE__ */ e(ue, { variant: "error", icon: /* @__PURE__ */ e(Pe, {}), children: b }),
          /* @__PURE__ */ e("div", { children: /* @__PURE__ */ e(
            X,
            {
              loading: $ || p,
              className: "w-full text-center",
              type: "submit",
              children: n("register:description")
            }
          ) }),
          /* @__PURE__ */ h("div", { className: "mt-4", children: [
            /* @__PURE__ */ e("b", { children: n("register:alreadyHaveAccount") }),
            /* @__PURE__ */ e("br", {}),
            /* @__PURE__ */ e(
              "button",
              {
                className: D(
                  "underline gamma:rounded-md",
                  "outline-none ring-offset-1 focus-visible:ring-3 focus-visible:ring-ignew-functional-focus-400"
                ),
                onClick: () => c({ flow: A.LOGIN }),
                children: n("register:loginUrl")
              }
            )
          ] })
        ]
      }
    )
  ] });
}
function Be() {
  const { MYACCOUNT_URL: r } = Y(), { i18n: n, t } = E(["register"]), l = se(
    () => le(r),
    [r]
  ), a = B((o) => {
    ne.addAction("resend_verification_failed", {
      errorDetails: o == null ? void 0 : o.message
    });
  }, []);
  function i({
    email: o,
    sms: s,
    phoneNumber: c
  }) {
    return l.resendVerification({
      email: o,
      locale: Ae(n.language),
      sms: s,
      phoneNumber: c,
      verificationMethod: "CODE"
    }).catch((u) => {
      var d;
      if (N({
        type: "resend_verification_error",
        data: {
          user_message_type: (u == null ? void 0 : u.message) || "",
          verificationMethod: s ? "sms" : "email"
        }
      }), ve(u) && ((d = u.response) == null ? void 0 : d.data).status === "ResendInvalidEmail")
        throw new T(
          t(s ? "register:phoneNumberAlreadyExists" : "register:userAlreadyExists")
        );
    }).finally(() => {
      N({
        type: "resend_verification",
        data: {
          verificationMethod: s ? "sms" : "email"
        }
      });
    });
  }
  return me({
    mutationFn: i,
    throwOnError: !1,
    onError: (o) => {
      a(o);
    }
  });
}
function wt({
  email: r,
  goToStep: n,
  goToFlow: t
}) {
  const { t: l } = E("login-form"), { mutate: a, isPending: i, isSuccess: o, data: s } = Be();
  I(() => {
    o && s && t({
      flow: A.VERIFY,
      verificationData: {
        customerId: s.customerId,
        control: s.control,
        email: s.email
      }
    });
  }, [o, n, t, s]);
  const c = () => {
    N({ type: "sign_up_change_email" }), n(te.LOGIN);
  };
  return /* @__PURE__ */ h("div", { className: "flex flex-col gap-4", children: [
    /* @__PURE__ */ h("div", { className: "grid gap-4", children: [
      /* @__PURE__ */ e("p", { children: l("bodyNoVerfication") }),
      /* @__PURE__ */ e("p", { children: l("bodyNoVerification2") }),
      /* @__PURE__ */ e(J, { children: /* @__PURE__ */ e("p", { className: "text-center", children: /* @__PURE__ */ e("strong", { children: r }) }) })
    ] }),
    /* @__PURE__ */ e("div", { children: /* @__PURE__ */ e(
      X,
      {
        className: "w-full",
        loading: i,
        onClick: () => a({ email: r }),
        children: l("buttonLabelResendEmail")
      }
    ) }),
    /* @__PURE__ */ e("div", { className: "text-center", children: /* @__PURE__ */ e(
      "button",
      {
        className: D(
          "underline gamma:rounded-md",
          "outline-none ring-offset-1 focus-visible:ring-3 focus-visible:ring-ignew-functional-focus-400"
        ),
        onClick: c,
        children: l("buttonLabelOtherEmail")
      }
    ) })
  ] });
}
function vt({ email: r }) {
  const { t: n } = E("login-form");
  return /* @__PURE__ */ h("div", { className: "grid gap-4", children: [
    /* @__PURE__ */ e("p", { children: n("bodyConfirmEmailSent") }),
    /* @__PURE__ */ e("p", { className: "text-center", children: /* @__PURE__ */ e(J, { children: /* @__PURE__ */ e("strong", { children: r }) }) }),
    /* @__PURE__ */ e("p", { children: n("bodyEmailSteps") })
  ] });
}
function yt({ onSubmit: r, isLoading: n }) {
  const { t } = E("login-form"), { brand: l, country: a } = Y();
  let i = [];
  l === "gamma" ? i = a === "be" || a === "BE" ? ["2613", "2614", "2617", "2618", "2629"] : ["2611", "2615", "2616"] : l === "karwei" && (i = ["2612", "2619"]);
  const s = U.object({
    loyaltyCardNumber: U.string().min(1, t("cardNumberRequired")).length(13, t("LoyaltyCardNumberInvalid")).refine((d) => i.some((m) => d.startsWith(m)), {
      message: t("LoyaltyCardNumberInvalid")
    }).refine(
      (d) => d.split("").reduce(
        (p, g, R) => R % 2 === 0 ? p + Number(g) : p + 3 * Number(g),
        0
      ) % 10 === 0,
      {
        message: t("LoyaltyCardNumberInvalid")
      }
    )
  }), c = he({
    mode: "onBlur",
    defaultValues: {
      loyaltyCardNumber: ""
    },
    resolver: we(s)
  }), u = c.handleSubmit((d) => {
    r(d.loyaltyCardNumber);
  });
  return /* @__PURE__ */ h(re, { children: [
    /* @__PURE__ */ h("div", { className: "relative flex p-4 items-center", children: [
      /* @__PURE__ */ e("div", { className: "flex-grow border-t border-ignew-neutral-500" }),
      /* @__PURE__ */ e("span", { className: "flex-shrink mx-4 text-ignew-neutral-500 uppercase", children: t("or") }),
      /* @__PURE__ */ e("div", { className: "flex-grow border-t border-ignew-neutral-500" })
    ] }),
    /* @__PURE__ */ h(
      "form",
      {
        noValidate: !0,
        onSubmit: (d) => (d.stopPropagation(), u(d)),
        className: "flex flex-col gap-4",
        children: [
          /* @__PURE__ */ e(
            fe,
            {
              rules: {
                required: t("cardNumberRequired"),
                pattern: {
                  value: /^\d+$/,
                  message: t("LoyaltyCardNumberInvalid")
                }
              },
              render: ({ field: { onChange: d, ...m } }) => {
                var p;
                return /* @__PURE__ */ e(
                  de,
                  {
                    onChange: (g) => {
                      (g.target.value === "" || /^[0-9\b]+$/.test(g.target.value)) && d(g.target.value);
                    },
                    type: "text",
                    label: t("enterLoyaltyCardNumber"),
                    maxLength: 13,
                    inputMode: "numeric",
                    error: (p = c.formState.errors.loyaltyCardNumber) == null ? void 0 : p.message,
                    ...m
                  }
                );
              },
              control: c.control,
              name: "loyaltyCardNumber",
              defaultValue: ""
            }
          ),
          /* @__PURE__ */ e(X, { type: "submit", variant: "secondary", loading: n, children: t("RegisterLoyaltyCardNumber") })
        ]
      }
    )
  ] });
}
function bt({
  showTermsAndConditions: r,
  showPasswordField: n
}) {
  const { t } = E(["ig-header", "login"]);
  return U.object({
    email: U.string().min(1, t("ig-header:loginEmailRequired")).email(t("ig-header:loginEmailInvalid")),
    password: n ? U.string().min(1, t("ig-header:loginRequired")) : U.string(),
    requirements: r ? U.literal(!0, {
      errorMap: () => ({ message: t("login:requirementsError") })
    }) : U.any()
  });
}
function Ct({
  isLoading: r,
  onLogin: n,
  errorMessage: t,
  setShowLoyaltyBenefits: l,
  loyaltyCardLogin: a,
  initLocation: i,
  loginData: o
}) {
  var Le;
  const { t: s, i18n: c } = E([
    "ig-header",
    "login",
    "urls",
    "login-form",
    "register",
    "common"
  ]), u = Oe("abtest_drl_2846-drl_2"), d = Ze(), [m, p] = _(null), { signUpRequestBody: g, setSignUpRequestBody: R, setFlow: y, loginAfterSignUp: k } = ae(), { brand: H, SHOPFRONT_URL: V } = Y(), $ = Qe(), [b, j] = _(
    !!(o != null && o.email)
  ), [P, G] = _(void 0), [q, C] = _(!1), [O, W] = _(te.LOGIN), L = $e(H, c.language, V), Z = (L == null ? void 0 : L.requirementsLoyalty) ?? "/", oe = (L == null ? void 0 : L.loyaltyProgram) ?? "/", {
    accountState: S,
    getAccountState: ee,
    refetch: ie,
    error: K,
    isFetching: w
  } = De(), M = k ? g.email : ((Le = d.state) == null ? void 0 : Le.email) || (o == null ? void 0 : o.email), f = bt({
    showTermsAndConditions: q,
    showPasswordField: b
  }), v = he({
    defaultValues: {
      email: M || "",
      password: "",
      requirements: !1
    },
    resolver: we(f)
  }), be = () => {
    P && (v.setValue("email", P.full), G(void 0), N({ type: "email_suggestion_click" }));
  }, Ye = v.watch("email"), He = async (x) => {
    const z = await v.trigger("email");
    x && z && (x === g.email && S ? ie() : ee(x), R((Q) => ({
      ...Q,
      email: x,
      password: ""
    })), N({ type: "login_email_check" }));
  }, Ce = B(
    (x) => {
      x != null && x.length && (S != null && S.customerId) && (N({
        type: "login_interaction",
        data: {
          user_selected_element: "Login by code"
        }
      }), y({
        flow: A.VERIFY,
        verificationData: {
          email: x,
          customerId: S.customerId,
          loginViaCode: !0
        }
      }));
    },
    [S, y]
  );
  I(() => {
    o && !S && !w && ee(o.email);
  }, [o, S, w, ee]), I(() => {
    var x;
    if (S && !w) {
      const { hasAccount: z, hasLoyaltyCard: Q, verified: F, hasPassword: Se } = S;
      (x = document.getElementById(`password_${$}`)) == null || x.focus(), z ? F ? /* If in kiosk mode, start the login by code flow by default */ /* When showPasswordField is true it is redirected from login with code */ m && !b || // If a user has no password but wants to reset it, we should show the password field.
      !Se && !(o != null && o.forcePasswordFlow) || u === "B-new" && !(o != null && o.forcePasswordFlow) ? Ce(g.email) : (j(!0), v.setFocus("password")) : W(te.RESEND_VERIFICATION) : y({ flow: A.SIGN_UP }), z && !Q && (C(!0), typeof l < "u" && l(!0));
    }
  }, [
    S,
    w,
    l,
    $,
    y,
    g.email,
    m
  ]), I(() => {
    (async () => {
      p(await ot());
    })();
  }, []), I(() => {
    k && typeof l < "u" && l(!0);
  }, [k, l]), I(() => {
    O === te.LOGIN && N({
      type: "login_interaction",
      data: {
        user_selected_element: b ? "Login pop-up" : "Login / Signup email check pop-up",
        ...!b && { user_selected_value: i }
      }
    });
  }, [O, b, i]);
  const je = v.handleSubmit((x) => {
    const { email: z, password: Q } = x;
    S != null && S.customerId && Q && n({
      body: {
        customerId: S.customerId,
        email: z,
        password: Q
      }
    });
  });
  return /* @__PURE__ */ e("div", { "data-testid": "login-form", children: ((x) => {
    var z, Q;
    switch (x) {
      case te.LOGIN:
        return /* @__PURE__ */ h(re, { children: [
          /* @__PURE__ */ h(
            "form",
            {
              noValidate: !0,
              onSubmit: (F) => {
                F.stopPropagation(), je(F);
              },
              className: "flex flex-col gap-4",
              children: [
                /* @__PURE__ */ e("h4", { className: "text-200 font-bold", children: s(b ? "login-form:loginTitle" : "login-form:titleLogInOrSignUp") }),
                /* @__PURE__ */ e("p", { children: b ? /* @__PURE__ */ h(re, { children: [
                  s("login-form:bodyWelcomeBack"),
                  /* @__PURE__ */ e("br", {}),
                  s("login-form:bodyPasswordRequest")
                ] }) : s("login-form:bodyUnsureAccount") }),
                /* @__PURE__ */ e("div", { children: /* @__PURE__ */ e(J, { children: /* @__PURE__ */ e("div", { className: "relative", children: /* @__PURE__ */ e(
                  de,
                  {
                    type: "email",
                    label: s("common:EmailAddress"),
                    error: (z = v.formState.errors.email) == null ? void 0 : z.message,
                    autoCapitalize: "off",
                    autoComplete: "email",
                    disabled: b,
                    "data-testid": "login-email-input",
                    description: P ? /* @__PURE__ */ h("span", { role: "alert", children: [
                      s("common:DidYouMean"),
                      " ",
                      /* @__PURE__ */ h(
                        "button",
                        {
                          className: D(
                            "underline",
                            "gamma:rounded-md outline-none ring-offset-1 focus-visible:ring-3 focus-visible:ring-ignew-functional-focus-400"
                          ),
                          onClick: be,
                          children: [
                            "@",
                            P.domain
                          ]
                        }
                      ),
                      "?"
                    ] }) : void 0,
                    icon: b && /* @__PURE__ */ e(
                      "button",
                      {
                        className: D(
                          "bg-transparent border-none p-0 cursor-pointer text-2xl gamma:rounded-md",
                          "outline-none ring-offset-1 focus-visible:ring-3 focus-visible:ring-ignew-functional-focus-400"
                        ),
                        "data-testid": "login-change-email",
                        onClick: (F) => {
                          F.preventDefault(), N({
                            type: "login_interaction",
                            data: {
                              user_selected_element: "Login pop-up change e-mail link click"
                            }
                          }), v.reset(), j(!1);
                        },
                        type: "button",
                        children: /* @__PURE__ */ e(pe, {})
                      }
                    ),
                    ...v.register("email", {
                      onChange(F) {
                        it(
                          F.target.value,
                          G
                        );
                      }
                    })
                  }
                ) }) }) }),
                /* @__PURE__ */ e(
                  "div",
                  {
                    className: b ? "" : "h-0 overflow-hidden absolute",
                    children: /* @__PURE__ */ e(J, { children: /* @__PURE__ */ e(
                      ke,
                      {
                        ...!b && {
                          tabIndex: -1,
                          "aria-hidden": "true"
                        },
                        autoComplete: b ? "current-password" : "off",
                        label: s("ig-header:loginPasswordLabel"),
                        error: ((Q = v.formState.errors.password) == null ? void 0 : Q.message) || t,
                        ...v.register("password")
                      }
                    ) })
                  }
                ),
                q && /* @__PURE__ */ e("div", { children: /* @__PURE__ */ e(
                  fe,
                  {
                    control: v.control,
                    name: "requirements",
                    defaultValue: !1,
                    render: ({ field: F, fieldState: Se }) => {
                      var xe;
                      return /* @__PURE__ */ e(
                        Ee,
                        {
                          ref: F.ref,
                          name: F.name,
                          checked: F.value,
                          onCheckedChange: (Ve) => {
                            Ve !== "indeterminate" && F.onChange(Ve);
                          },
                          error: (xe = Se.error) == null ? void 0 : xe.message,
                          label: /* @__PURE__ */ h(Re, { ns: "login", i18nKey: "requirements", children: [
                            "Met het aanmaken van een account, ga ik akkoord met de",
                            /* @__PURE__ */ e(
                              "a",
                              {
                                target: "_blank",
                                href: Z,
                                rel: "noreferrer",
                                children: "voorwaarden"
                              }
                            ),
                            "en profiteer ik van alle gratis voordelen van de",
                            /* @__PURE__ */ e(
                              "a",
                              {
                                target: "_blank",
                                href: oe,
                                rel: "noreferrer",
                                children: "pas"
                              }
                            )
                          ] })
                        }
                      );
                    }
                  }
                ) }),
                (K == null ? void 0 : K.message) && /* @__PURE__ */ e(ue, { variant: "error", icon: /* @__PURE__ */ e(Pe, {}), children: K.message }),
                /* @__PURE__ */ e("div", { children: b ? /* @__PURE__ */ e(
                  X,
                  {
                    loading: r,
                    className: "w-full text-center",
                    "data-testid": "login-submit",
                    type: "submit",
                    children: s("ig-header:Login")
                  }
                ) : /* @__PURE__ */ e(
                  X,
                  {
                    loading: w,
                    className: "w-full text-center",
                    onClick: () => He(Ye),
                    "data-testid": "login-next",
                    children: s("login-form:buttonlabel")
                  }
                ) }),
                b && S && /* @__PURE__ */ h("div", { className: "text-center", children: [
                  /* @__PURE__ */ e("div", { className: "mb-4", children: /* @__PURE__ */ e(
                    "button",
                    {
                      className: D(
                        "underline gamma:rounded-md",
                        "outline-none ring-offset-1 focus-visible:ring-3 focus-visible:ring-ignew-functional-focus-400"
                      ),
                      onClick: (F) => {
                        F.preventDefault(), N({
                          type: "login_interaction",
                          data: {
                            user_selected_element: "Login pop-up password reset link click"
                          }
                        }), y({
                          flow: A.FORGOT_PASSWORD,
                          forgotPasswordData: {
                            email: v.getValues().email,
                            customerId: S.customerId
                          }
                        });
                      },
                      children: s("ig-header:loginForgotPassword")
                    }
                  ) }),
                  /* @__PURE__ */ e(
                    "button",
                    {
                      className: D(
                        "underline gamma:rounded-md",
                        "outline-none ring-offset-1 focus-visible:ring-3 focus-visible:ring-ignew-functional-focus-400"
                      ),
                      onClick: () => {
                        Ce(v.getValues().email);
                      },
                      children: s("login-form:loginWithCodeLink")
                    }
                  )
                ] })
              ]
            }
          ),
          typeof a < "u" && /* @__PURE__ */ e(
            yt,
            {
              onSubmit: a,
              isLoading: r
            }
          )
        ] });
      case te.RESEND_VERIFICATION:
        return /* @__PURE__ */ h(re, { children: [
          /* @__PURE__ */ e("h4", { className: "mb-4 text-200 font-bold", children: s("login-form:loginTitle") }),
          /* @__PURE__ */ e(
            wt,
            {
              email: g.email,
              goToStep: W,
              goToFlow: y
            }
          )
        ] });
      case te.RESEND_SUCCESS:
        return /* @__PURE__ */ h(re, { children: [
          /* @__PURE__ */ e("h4", { className: "mb-4 text-200 font-bold", children: s("register:titleIsThatYou") }),
          /* @__PURE__ */ e(vt, { email: g.email })
        ] });
      default:
        return null;
    }
  })(O) });
}
function St() {
  const { MYACCOUNT_URL: r } = Y(), { setFlow: n, setSignUpResponse: t, setVerificationError: l } = ae(), { t: a } = E(["register", "errors", "login"]), i = B(() => {
    ne.addAction("verified_code");
  }, []), o = B((u) => {
    ne.addAction("verified_code_failed", {
      errorDetails: u == null ? void 0 : u.message
    });
  }, []), s = se(
    () => le(r),
    [r]
  );
  function c({ verificationCode: u, customerId: d }) {
    return s.verify("signup", `${d}_${u}`, d).catch((m) => {
      var p;
      if (ve(m)) {
        const g = (p = m.response) == null ? void 0 : p.data;
        if (g.status === "VerifySignUpInvalidToken")
          if (g.httpStatus === 410)
            t(void 0), l(a("register:tooManyWrongAttempts")), n({ flow: A.SIGN_UP });
          else
            throw new T(a("register:errorNotValidCode"));
      }
      throw ge.error("Verify code form failed", {}, m), (m == null ? void 0 : m.response.status) === 429 ? new T(a("errors:tooManyVerificationAttempts")) : new T(
        `${a("errors:somethingWentWrong")} ${a(
          "errors:ContactCustomerSupport"
        )}`
      );
    });
  }
  return me({
    mutationFn: c,
    throwOnError: !1,
    onSuccess: () => {
      N({
        type: "verify_email"
      }), i();
    },
    onError: (u) => {
      N({
        type: "verify_email_error",
        data: {
          error: u == null ? void 0 : u.message
        }
      }), o(u);
    }
  });
}
const Ge = (r) => /^\d+$/.test(r);
function Nt() {
  const [r, n] = _(!1), t = B(async (l) => {
    n(!0);
    try {
      return await at({
        username: l,
        options: {
          authFlowType: "CUSTOM_WITHOUT_SRP"
        }
      });
    } catch {
      return null;
    } finally {
      n(!1);
    }
  }, []);
  return {
    isPending: r,
    requestVerificationCode: t
  };
}
function _t({
  navigateToLogin: r = !1,
  email: n,
  customerId: t
}) {
  const { setFlow: l, setLoginAfterSignUp: a } = ae(), { t: i } = E(["verification", "common", "urls"]);
  return I(() => {
    ye.success(i("verification:signupVerificationSuccessDescription"), {
      icon: Ie
    }), l({ flow: A.LOGIN }), a(!0), N({
      type: "sign_up_activation",
      data: { kdbid: t }
    });
  }, [i, l, a, t]), r ? /* @__PURE__ */ e(
    et,
    {
      to: { pathname: i("urls:/login"), search: "type=new" },
      state: { email: n },
      replace: !0
    }
  ) : null;
}
const Ne = 4;
function Et({
  verificationData: r,
  navigateToLogin: n = !1,
  onLogin: t,
  confirmSignInError: l
}) {
  const { customerId: a, email: i, control: o = "A", loginViaCode: s } = r, { t: c } = E(["register", "common", "login-form"]), [u, d] = _(""), [m, p] = _(null), [g, R] = _(!1), y = ce(null), k = ce(null), [H, V] = _(!1), { setFlow: $, signUpRequestBody: b, setVerificationError: j } = ae(), {
    isPending: P,
    requestVerificationCode: G
  } = Nt(), q = ce(t), {
    mutate: C,
    error: O,
    data: W
  } = Be(), {
    mutate: L,
    isSuccess: Z,
    isError: oe,
    error: S
  } = St();
  I(() => {
    var f;
    (f = document.getElementById("code")) == null || f.focus();
  }, [P]), I(() => {
    s && G(a);
  }, [s, a, G]), I(() => {
    oe && V(!1);
  }, [oe]), I(() => {
    var f;
    Z && q.current && ((f = b.password) != null && f.length) ? (N({
      type: "sign_up_activation",
      data: { kdbid: a }
    }), q.current({
      body: {
        email: i,
        customerId: a,
        password: b.password
      },
      disableAdobeEvent: !0
    })) : V(!1);
  }, [Z, q, b, a, i]);
  const ee = (f) => {
    if (f == null || f.stopPropagation(), f == null || f.preventDefault(), j(void 0), y.current && clearTimeout(y.current), !(u.length === Ne)) {
      p(c("register:errorNotValidCode"));
      return;
    }
    a && !s && (p(null), V(!0), L({ verificationCode: u, customerId: a })), s && q.current && (p(null), V(!0), q.current({
      body: {
        email: i,
        customerId: a,
        verificationCode: u
      }
    }));
  };
  I(() => {
    var f;
    return u.length === Ne && Ge(u) ? ((f = k.current) == null || f.blur(), y.current = setTimeout(() => {
      ee();
    }, 1e3)) : y.current && clearTimeout(y.current), () => {
      y.current && clearTimeout(y.current);
    };
  }, [u]), I(() => {
    const f = (S == null ? void 0 : S.message) || (O == null ? void 0 : O.message);
    f && (p(f), setTimeout(() => {
      var v;
      (v = document.getElementById("code")) == null || v.focus();
    }, 1));
  }, [O, S]);
  const ie = (f) => {
    const { value: v } = f.target, be = v.replace(/\D/g, "");
    d(be);
  }, K = (f) => {
    f.preventDefault(), d(""), p(null), r.loginViaCode ? G(a) : C({
      email: i
    }), ye.success(c("register:successMessageResendCode"), { icon: Ie }), R(!0), setTimeout(() => {
      R(!1);
    }, 3e4);
  }, w = (f) => {
    N({
      type: "login_interaction",
      data: {
        user_selected_element: "Login by password"
      }
    }), $({
      flow: A.LOGIN,
      loginData: {
        email: f,
        // If a user has no password it would not show the password input.
        // This forces the input to show up.
        forcePasswordFlow: !0
      }
    });
  };
  if (I(() => {
    var f;
    l && (V(!1), p(l), (f = k.current) == null || f.focus());
  }, [l, m, c, $]), Z && !H)
    return /* @__PURE__ */ e(
      _t,
      {
        navigateToLogin: n,
        email: i,
        customerId: a
      }
    );
  const M = (W == null ? void 0 : W.control) || o;
  return /* @__PURE__ */ h("div", { className: "flex flex-col gap-4", children: [
    /* @__PURE__ */ e("h4", { className: "text-200 font-bold", children: c(s ? "login-form:loginCodeSentTitle" : "register:titleIsThatYou") }),
    /* @__PURE__ */ e("div", { children: c(s ? "login-form:loginCodeSentDescription" : "register:bodyCodeSentTo") }),
    /* @__PURE__ */ e(J, { children: /* @__PURE__ */ e(
      de,
      {
        label: "",
        value: i,
        disabled: !0,
        icon: /* @__PURE__ */ e(
          "button",
          {
            className: D(
              "underline bg-transparent gamma:rounded-md",
              "outline-none ring-offset-1 focus-visible:ring-3 focus-visible:ring-ignew-functional-focus-400"
            ),
            onClick: () => {
              $({ flow: A.LOGIN }), N({ type: "sign_up_change_email" });
            },
            "data-testid": "change-email-button",
            type: "button",
            children: /* @__PURE__ */ e(pe, {})
          }
        )
      }
    ) }),
    /* @__PURE__ */ e("p", { children: c(s ? "login-form:codeValidity" : "register:bodyFillCode") }),
    /* @__PURE__ */ h(
      "form",
      {
        className: "flex flex-col gap-4",
        noValidate: !0,
        onSubmit: ee,
        method: "POST",
        children: [
          /* @__PURE__ */ e(
            Te,
            {
              label: c("login-form:verifyNewPasswordCodeLabel"),
              value: u,
              "data-testid": "code",
              onChange: ie,
              autoFocus: !0,
              disabled: P,
              maxLength: Ne,
              controlCharacter: M,
              description: c("login-form:verifyNewPasswordCodeDescription"),
              controlCharacterProps: {
                "data-testid": `control-character-${M}`
              }
            }
          ),
          m && /* @__PURE__ */ e(ue, { variant: "error", children: m }),
          /* @__PURE__ */ e("div", { children: /* @__PURE__ */ e(
            X,
            {
              type: "submit",
              className: "w-full",
              loading: H || P,
              children: c(H ? "register:isVerifying" : "register:confirm")
            }
          ) }),
          /* @__PURE__ */ e("div", { className: "mt-2 text-center", children: /* @__PURE__ */ e(
            "button",
            {
              type: "button",
              className: D(
                "underline gamma:rounded-md",
                "outline-none ring-offset-1 focus-visible:ring-3 focus-visible:ring-ignew-functional-focus-400"
              ),
              disabled: g,
              onClick: (f) => K(f),
              children: c("register:buttonLabelResendCode")
            }
          ) }),
          /* @__PURE__ */ e("div", { className: "mt-2 text-center", children: /* @__PURE__ */ e(
            "button",
            {
              type: "button",
              className: D(
                "underline gamma:rounded-md",
                "outline-none ring-offset-1 focus-visible:ring-3 focus-visible:ring-ignew-functional-focus-400"
              ),
              onClick: () => {
                w(i);
              },
              children: c("login-form:loginPasswordLink")
            }
          ) })
        ]
      }
    )
  ] });
}
function We() {
  const { MYACCOUNT_URL: r } = Y(), { t: n } = E(["register", "errors"]), t = se(
    () => le(r),
    [r]
  ), l = B(() => {
    ne.addAction("request_new_password");
  }, []), a = B(
    (o) => {
      ne.addAction("request_new_password_failed", {
        reason: o
      });
    },
    []
  );
  function i(o) {
    return t.passwordReset({ email: o, verificationMethod: qe.CODE }).catch((s) => {
      throw ge.error("Reset password request failed", {}, s), (s == null ? void 0 : s.response.status) === 429 ? new T(n("errors:tooManyVerificationAttempts")) : new T(
        `${n("errors:somethingWentWrong")} ${n(
          "errors:ContactCustomerSupport"
        )}`
      );
    });
  }
  return me({
    mutationFn: i,
    onSuccess: () => {
      l();
    },
    onError: (o) => {
      a((o == null ? void 0 : o.message) || "");
    }
  });
}
function Rt({ email: r, onSuccess: n }) {
  var p;
  const { t } = E([
    "register",
    "errors",
    "ig-form",
    "urls",
    "login-form"
  ]), l = U.object({
    password: U.string().min(8, t("register:minPasswordLength")).max(50, t("register:maxPasswordLength"))
  }), a = he({
    defaultValues: {
      password: ""
    },
    resolver: we(l)
  }), i = a.watch("password"), o = se(
    () => Fe(i),
    [i]
  ), s = Ue(o), { mutate: c, isPending: u, error: d } = We(), m = a.handleSubmit((g) => {
    c(r, {
      onSuccess({ control: R }) {
        n(g.password, R);
      }
    });
  });
  return /* @__PURE__ */ h(
    "form",
    {
      noValidate: !0,
      onSubmit: (g) => {
        g.stopPropagation(), m(g);
      },
      className: "flex flex-col gap-4",
      children: [
        /* @__PURE__ */ h("div", { className: "grid gap-4", children: [
          /* @__PURE__ */ e("h4", { className: "mb-4 text-200 font-bold", children: t("login-form:chooseNewPasswordTitle") }),
          /* @__PURE__ */ e("p", { children: t("login-form:chooseNewPasswordBody") })
        ] }),
        /* @__PURE__ */ e("div", { children: /* @__PURE__ */ e(J, { children: /* @__PURE__ */ e(
          ke,
          {
            autoFocus: !0,
            autoComplete: "new-password",
            label: t("login-form:Password"),
            error: (p = a.formState.errors.password) == null ? void 0 : p.message,
            passwordStrength: o,
            description: s,
            ...a.register("password")
          }
        ) }) }),
        (d == null ? void 0 : d.message) && /* @__PURE__ */ e(ue, { variant: "error", icon: /* @__PURE__ */ e(Pe, {}), children: d.message }),
        /* @__PURE__ */ e("div", { children: /* @__PURE__ */ e(
          X,
          {
            disabled: u,
            loading: u,
            className: "w-full text-center",
            children: t("login-form:buttonlabel")
          }
        ) })
      ]
    }
  );
}
function kt() {
  const { MYACCOUNT_URL: r } = Y(), { t: n } = E(["register", "errors"]), t = se(
    () => le(r),
    [r]
  ), l = B(() => {
    ne.addAction("verified_new_password");
  }, []), a = B((o) => {
    ne.addAction("verifiy_new_password_failed", {
      reason: o
    });
  }, []);
  function i(o) {
    return t.verifyPasswordReset(o).catch((s) => {
      var c;
      if (ve(s)) {
        const u = (c = s.response) == null ? void 0 : c.data;
        if (u.status === "VerifyPasswordResetInvalidToken")
          throw u.httpStatus === 410 ? new T(n("register:tooManyWrongAttempts")) : new T(n("register:errorNotValidCode"));
      }
      throw ge.error("Verify code form failed", {}, s), (s == null ? void 0 : s.response.status) === 429 ? new T(n("errors:tooManyVerificationAttempts")) : new T(
        `${n("errors:somethingWentWrong")} ${n(
          "errors:ContactCustomerSupport"
        )}`
      );
    });
  }
  return me({
    mutationFn: i,
    throwOnError: !1,
    onSuccess: () => {
      N({ type: "login_change_password" }), l();
    },
    onError: (o) => {
      a((o == null ? void 0 : o.message) || "");
    }
  });
}
const _e = 4;
function It({
  email: r,
  password: n,
  customerId: t,
  control: l,
  onSuccess: a
}) {
  const { t: i } = E([
    "register",
    "errors",
    "ig-form",
    "urls",
    "login-form"
  ]), [o, s] = _(""), [c, u] = _(null), d = ce(null), m = ce(null), { setFlow: p } = ae(), {
    mutate: g,
    isPending: R,
    error: y
  } = kt(), {
    data: k,
    mutate: H,
    error: V
  } = We(), [$, b] = _(!1), j = (C) => {
    const { value: O } = C.target, W = O.replace(/\D/g, "");
    s(W);
  }, P = (C) => {
    if (C == null || C.stopPropagation(), C == null || C.preventDefault(), d.current && clearTimeout(d.current), !(o.length === _e)) {
      u(i("register:errorNotValidCode"));
      return;
    }
    g({ newPassword: n, customerId: t, code: o }, { onSuccess: a });
  };
  I(() => {
    var C;
    return o.length === _e && Ge(o) ? ((C = m.current) == null || C.blur(), d.current = setTimeout(() => {
      P();
    }, 1e3)) : d.current && clearTimeout(d.current), () => {
      d.current && clearTimeout(d.current);
    };
  }, [o]), I(() => {
    const C = (y == null ? void 0 : y.message) || (V == null ? void 0 : V.message);
    C && (u(C), setTimeout(() => {
      var O;
      (O = m.current) == null || O.focus();
    }, 1));
  }, [V, y]);
  const G = (C) => {
    C.preventDefault(), s(""), H(r, {
      onSuccess() {
        ye.success(i("register:successMessageResendCode"), {
          icon: Ie
        }), u(null);
      }
    }), b(!0), setTimeout(() => {
      b(!1);
    }, 3e4);
  }, q = (k == null ? void 0 : k.control) || l;
  return /* @__PURE__ */ h(re, { children: [
    /* @__PURE__ */ e("h4", { className: "mb-4 text-200 font-bold", children: i("login-form:verifyNewPasswordTitle") }),
    /* @__PURE__ */ e("div", { className: "mb-4", children: i("login-form:verifyNewPasswordBody") }),
    /* @__PURE__ */ e(J, { children: /* @__PURE__ */ e(
      de,
      {
        label: "",
        value: r,
        disabled: !0,
        icon: /* @__PURE__ */ e(
          "button",
          {
            className: D(
              "underline bg-transparent gamma:rounded-md",
              "outline-none ring-offset-1 focus-visible:ring-3 focus-visible:ring-ignew-functional-focus-400"
            ),
            onClick: () => p({ flow: A.LOGIN }),
            "data-testid": "change-email-button",
            type: "button",
            children: /* @__PURE__ */ e(pe, {})
          }
        )
      }
    ) }),
    /* @__PURE__ */ h(
      "form",
      {
        className: "flex flex-col gap-4 mt-4",
        noValidate: !0,
        onSubmit: P,
        method: "POST",
        children: [
          /* @__PURE__ */ e(
            Te,
            {
              label: i("login-form:verifyNewPasswordCodeLabel"),
              value: o,
              "data-testid": "code",
              autoFocus: !0,
              onChange: j,
              disabled: R,
              maxLength: _e,
              controlCharacter: q,
              description: i("login-form:verifyNewPasswordCodeDescription"),
              controlCharacterProps: {
                "data-testid": `control-character-${q}`
              }
            }
          ),
          c && /* @__PURE__ */ e(ue, { variant: "error", children: c }),
          /* @__PURE__ */ e("div", { children: /* @__PURE__ */ e(X, { className: "w-full", loading: R, children: i(R ? "register:isVerifying" : "register:confirm") }) }),
          /* @__PURE__ */ e("div", { className: "mt-2 text-center", children: /* @__PURE__ */ e(
            "button",
            {
              className: D(
                "underline gamma:rounded-md",
                "outline-none ring-offset-1 focus-visible:ring-3 focus-visible:ring-ignew-functional-focus-400"
              ),
              disabled: $,
              onClick: (C) => G(C),
              children: i("register:buttonLabelResendCode")
            }
          ) })
        ]
      }
    )
  ] });
}
function Pt({
  values: r,
  onLogin: n
}) {
  const [t, l] = _({
    step: "CHOOSE_PASSWORD",
    email: r.email
  }), { t: a } = E(["change-password"]);
  return /* @__PURE__ */ h(re, { children: [
    t.step === "CHOOSE_PASSWORD" && /* @__PURE__ */ e(
      Rt,
      {
        email: t.email,
        onSuccess: (i, o) => {
          l({
            step: "VERIFY_REQUEST",
            email: r.email,
            password: i,
            control: o
          });
        }
      }
    ),
    t.step === "VERIFY_REQUEST" && /* @__PURE__ */ e(
      It,
      {
        customerId: r.customerId,
        email: t.email,
        password: t.password,
        control: t.control,
        onSuccess: () => {
          ye.success(a("change-password:successfulSave")), n({
            body: {
              email: t.email,
              customerId: r.customerId,
              password: t.password
            },
            disableAdobeEvent: !0
          });
        }
      }
    )
  ] });
}
function or(r) {
  const [n, t] = _({ flow: A.LOGIN }), [l, a] = _(), [i, o] = _(void 0), { i18n: s } = E(), [c, u] = _(!1), [d, m] = _(
    {
      email: "",
      password: "",
      locale: Ae(s.language),
      optIn: !1,
      verificationMethod: "CODE",
      passwordless: !1
    }
  );
  return /* @__PURE__ */ e("div", { className: "px-4 py-6", children: /* @__PURE__ */ h(mt, { value: {
    signUpRequestBody: d,
    setSignUpRequestBody: m,
    setFlow: t,
    loginAfterSignUp: c,
    setLoginAfterSignUp: u,
    signUpResponse: l,
    setSignUpResponse: a,
    setVerificationError: o,
    verificationError: i
  }, children: [
    n.flow === A.LOGIN && /* @__PURE__ */ e(Ct, { ...r, loginData: n.loginData }),
    n.flow === A.SIGN_UP && /* @__PURE__ */ e(
      ht,
      {
        setShowLoyaltyBenefits: r.setShowLoyaltyBenefits
      }
    ),
    n.flow === A.VERIFY && /* @__PURE__ */ e(
      Et,
      {
        verificationData: n.verificationData,
        navigateToLogin: r.isMyAccount,
        confirmSignInError: r.errorMessage,
        onLogin: r.onLogin
      }
    ),
    n.flow === A.FORGOT_PASSWORD && /* @__PURE__ */ e(
      Pt,
      {
        values: n.forgotPasswordData,
        onLogin: r.onLogin
      }
    )
  ] }) });
}
function ir({
  loyaltyCardNumber: r,
  close: n
}) {
  const { t, i18n: l } = E(["urls", "loyalty-benefits"]), { brand: a, MYACCOUNT_URL: i } = Y(), o = tt(), s = () => {
    n(), o(t("urls:/profile/loyaltycards"), {
      state: { showForm: !0 },
      replace: !0
    });
  }, c = `${i}/api/app/loyalty/cards/apple-wallet-pass`, u = ct() === dt.IOS, d = async (m) => {
    const p = m.currentTarget;
    if (!(p != null && p.href))
      try {
        const g = await st(), y = await (await fetch(c, {
          headers: {
            ...g,
            Accept: "application/vnd.apple.pkpass"
          }
        })).blob(), k = window.URL.createObjectURL(y);
        p.download = `${a}.pkpass`, p.href = k, p.click(), p.removeAttribute("href"), p.removeAttribute("download"), window.URL.revokeObjectURL(k);
      } catch {
        throw new Error("Failed to download loyalty card");
      }
  };
  return /* @__PURE__ */ h("div", { className: "p-0 md:p-4 grid grid-cols-2 gap-4", children: [
    /* @__PURE__ */ h("div", { className: "col-span-2", children: [
      /* @__PURE__ */ e("h3", { className: "mb-6 text-200/6 pr-6", children: t("loyalty-benefits:titleCongratsNewVdp") }),
      u && /* @__PURE__ */ e("div", { className: "mb-6 text-75/6", children: t("loyalty-benefits:welcomeAddWallet") }),
      /* @__PURE__ */ h("div", { className: "flex flex-row", children: [
        /* @__PURE__ */ e("div", { className: "w-6 h-6 mr-1", children: /* @__PURE__ */ e(Je, { className: "gamma:text-brand primary karwei:text-ignew-neutral-1000" }) }),
        /* @__PURE__ */ e("p", { children: /* @__PURE__ */ h(Re, { t, i18nKey: "loyalty-benefits:welcomeBenefits", children: [
          /* @__PURE__ */ e("strong", { children: "250" }),
          " welkomstpunten t.w.v. €2,50 zijn toegevoegd aan je account"
        ] }) })
      ] })
    ] }),
    /* @__PURE__ */ e("div", { className: "col-span-2", children: /* @__PURE__ */ e("div", { className: "w-full", children: /* @__PURE__ */ e(
      lt,
      {
        language: l.language,
        cardNumber: r
      }
    ) }) }),
    u && /* @__PURE__ */ e("div", { className: "col-span-2", children: /* @__PURE__ */ e("a", { onClick: d, className: "cursor-pointer", children: /* @__PURE__ */ e(
      "img",
      {
        src: l.language === "nl" ? "https://mijn.gamma.nl/static/images/apple_wallet_nl_full.svg" : "https://mijn.gamma.nl/static/images/apple_wallet_fr_full.svg",
        className: "w-full",
        alt: "Apple Wallet"
      }
    ) }) }),
    /* @__PURE__ */ e("div", { className: "col-span-2", children: /* @__PURE__ */ e(X, { onClick: s, className: "w-full", children: t("loyalty-benefits:buttonAddPhysicalPass") }) })
  ] });
}
export {
  or as A,
  A as F,
  ir as L,
  vt as R,
  ht as S,
  Et as V,
  Me as a,
  mt as b,
  Ct as c,
  yt as d,
  wt as e,
  _t as f,
  $e as g,
  De as h,
  ut as i,
  Be as j,
  gt as k,
  St as l,
  te as m,
  qe as n,
  ae as u,
  Ge as v
};
