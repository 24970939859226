import { jsx as n, jsxs as g, Fragment as M } from "react/jsx-runtime";
import { useConfig as b } from "@intergamma/config";
import { dispatchAdobeEvent as U } from "@intergamma/adobe-tracking";
import { useTranslation as d } from "react-i18next";
import { useMemo as F, useState as J } from "react";
import { cn as w } from "@intergamma/common/cn";
import { useActiveVariant as N } from "@intergamma/experiments/hooks";
import { X as Q } from "react-feather";
import { connectAutoComplete as H, connectStateResults as X, InstantSearch as E, Configure as G } from "react-instantsearch-dom";
import { Downshift as V, DownshiftMenu as W, DownshiftItems as Y, DownshiftItem as Z } from "@intergamma/downshift";
import { useFormula as ee } from "@intergamma/theme/provider";
import te from "algoliasearch";
import oe from "js-cookie";
const ne = () => {
  if (typeof localStorage > "u")
    return;
  const e = localStorage.getItem("queryList");
  if (e)
    try {
      return JSON.parse(e);
    } catch {
      return;
    }
};
function re(e, t) {
  const { t: r } = d("ig-search-bar");
  if (e.length !== 0)
    return t;
  const o = ne(), s = [];
  if (o && o.length > 0)
    for (let a = 0; a < o.length; a += 1) {
      const l = {
        query: o[a]
      };
      a === 0 && (l.typeLabel = r("RecentQueries")), s.push(l);
    }
  return s;
}
const A = ({ ...e }) => /* @__PURE__ */ n(
  "input",
  {
    ...e,
    className: w(
      "box-border w-full appearance-none border font-normal transition-all duration-200",
      "[&::-webkit-search-cancel-button]:appearance-none",
      "md:absolute md:right-0 md:transition-[width] md:duration-100",
      "focus:outline-none",
      "karwei:h-[40px] karwei:rounded-[3px] karwei:pl-[10px] karwei:pr-[40px]",
      "gamma:h-[46px] gamma:rounded-full gamma:pl-[17px] gamma:pr-[44px]",
      "border-ignew-neutral-400 bg-white text-black karwei:text-ignew-neutral-900",
      "gamma:focus:rounded-full karwei:focus:rounded-[3px]",
      "focus:border-brand-primary  focus:shadow-[0_0_0_3px] focus:shadow-brand-primary/20 karwei:focus:border-ignew-neutral-900",
      "md:text-[1em]",
      "lg:focus:w-[600px]",
      "peer"
    )
  }
);
function ae({ query: e, item: t }) {
  if (!t.query)
    return null;
  const r = t.query.substr(0, e.length);
  return /* @__PURE__ */ g("div", { className: "leading-[28px]", children: [
    String(r).valueOf() === String(e).valueOf() ? /* @__PURE__ */ n("strong", { children: e }) : r,
    t.query.substr(e.length),
    " ",
    t.typeLabel && /* @__PURE__ */ n("span", { className: "suggestion-type left-0 right-auto top-[-12px] text-[13px] first-letter:capitalize md:left-auto md:right-4 md:top-[-17px] ", children: t.typeLabel }),
    t.category && /* @__PURE__ */ n("span", { className: "rounded bg-ignew-functional-primary-25 text-functional-primary-700 px-2 py-1 ml-1", children: t.category })
  ] });
}
function ie({
  refine: e,
  hits: t,
  searchResults: r,
  value: o,
  setValue: s,
  onSelect: a,
  downshiftEnvironment: l
}) {
  const { isMobile: h } = b(), { t: p, i18n: m } = d("ig-search-bar"), c = ee(), x = (r == null ? void 0 : r.query) ?? "", S = re(x, t), z = N("abtest_xtd_autosug-ew_4"), D = c === "gamma_be" ? `_${m.language === "nl-BE" ? "nl" : "fr"}` : "", P = `prd_products_ms_${c}${D}`;
  return /* @__PURE__ */ n(
    V,
    {
      environment: l,
      selectedItem: { query: o },
      itemToString: (i) => typeof i == "object" ? (i == null ? void 0 : i.query) || "" : i || "",
      onSelect: (i) => {
        i && i.query && a(i);
      },
      onInputValueChange: (i) => {
        s(i);
      },
      children: ({
        getInputProps: i,
        getItemProps: B,
        getMenuProps: L,
        highlightedIndex: T,
        isOpen: O,
        openMenu: $
      }) => {
        const j = i({
          onFocus() {
            $();
          },
          onChange(u) {
            e(u.currentTarget.value);
          },
          autoComplete: "off",
          autoCapitalize: "off",
          autoCorrect: "off"
        });
        return /* @__PURE__ */ g(M, { children: [
          /* @__PURE__ */ n(
            A,
            {
              type: "search",
              name: "text",
              placeholder: p(h ? "Search" : "SearchByKeyword"),
              required: !0,
              ...j
            }
          ),
          /* @__PURE__ */ n(
            W,
            {
              ...L(),
              isOpen: O && S.length > 0,
              role: "form",
              "aria-label": p("Suggestions"),
              children: /* @__PURE__ */ n(Y, { role: "listbox", "aria-label": p("Suggestions"), children: S.map((u, f) => {
                var v, I, C, _;
                const K = B({
                  item: u,
                  index: f
                });
                u.showIndex = f + 1;
                const y = u[P];
                return z === "C-incl-cat" && [1, 2, 3].includes(f) && y && ((_ = (C = (I = (v = y.facets) == null ? void 0 : v.exact_matches) == null ? void 0 : I.categories) == null ? void 0 : C[0]) != null && _.value) && (u.category = y.facets.exact_matches.categories[0].value), /* @__PURE__ */ n(
                  Z,
                  {
                    highlighted: f === T,
                    ...K,
                    role: "option",
                    children: /* @__PURE__ */ n(ae, { query: x, item: u })
                  },
                  f
                );
              }) })
            }
          )
        ] });
      }
    }
  );
}
const se = H(
  X(ie)
), q = ({
  children: e,
  ...t
}) => /* @__PURE__ */ n("form", { ...t, className: "relative h-[46px] w-full karwei:h-[40px]", children: e });
function R() {
  const { t: e } = d("ig-search-bar");
  return /* @__PURE__ */ g(ce, { type: "submit", children: [
    /* @__PURE__ */ n("span", { className: "sr-only", children: e("Search") }),
    /* @__PURE__ */ n(
      "svg",
      {
        xmlns: "http://www.w3.org/2000/svg",
        viewBox: "0 0 250.3 250.3",
        width: "20",
        height: "20",
        focusable: "false",
        className: "absolute left-[11px] top-[11px] z-[1]",
        children: /* @__PURE__ */ n("path", { d: k, fill: "#fff", fillRule: "evenodd", clipRule: "evenodd" })
      }
    ),
    /* @__PURE__ */ n(
      "svg",
      {
        xmlns: "http://www.w3.org/2000/svg",
        viewBox: "0 0 250.3 250.3",
        width: "20",
        height: "20",
        className: "absolute left-[11px] top-3 z-0 opacity-[0.3]",
        focusable: "false",
        children: /* @__PURE__ */ n(
          "path",
          {
            d: k,
            fill: "#000",
            fillRule: "evenodd",
            clipRule: "evenodd",
            className: "shadow"
          }
        )
      }
    )
  ] });
}
const ce = ({
  children: e,
  ...t
}) => /* @__PURE__ */ n(
  "button",
  {
    ...t,
    className: w(
      "absolute z-[500] size-[40px] cursor-pointer border-none text-white transition-all duration-200",
      "bg-brand-primary karwei:bg-ignew-neutral-900",
      "karwei:right-0 karwei:top-0",
      "gamma:right-[3px] gamma:top-[3px]",
      "karwei:rounded-r-[3px]",
      "gamma:rounded-full",
      "focus:bg-ignew-functional-focus-900 focus:outline-none focus:karwei:bg-ignew-neutral-200",
      "focus:shadow-sm"
    ),
    children: e
  }
), k = "M244.2 214.6l-54.4-54.4-1-.7a103 103 0 1 0-29.4 29.4l.8 1 54.4 54.3a21 21 0 0 0 29.6-29.6zm-141.3-44.5a67.2 67.2 0 1 1 0-134.4 67.2 67.2 0 0 1 0 134.4z";
function le() {
  const e = b();
  return F(() => {
    const t = te(
      e.algoliaApplicationId,
      e.suggestionApiKey
    );
    return {
      ...t,
      search(o) {
        return o.every(({ params: s }) => !s.query) ? Promise.resolve({
          results: o.map(() => ({
            hits: [],
            nbHits: 0,
            nbPages: 0,
            page: 0,
            processingTimeMS: 0
          }))
        }) : t.search(o);
      }
    };
  }, [e.algoliaApplicationId, e.suggestionApiKey]);
}
function ue(e, t, r, o) {
  e != null && e.objectID && window.fetch("https://insights.algolia.io/1/events", {
    method: "POST",
    headers: {
      "X-Algolia-Application-Id": t,
      "X-Algolia-API-Key": r,
      "Content-Type": "application/json"
    },
    body: JSON.stringify({
      events: [
        {
          eventType: "click",
          eventName: "Search Suggestion Clicked",
          index: o,
          userToken: oe.get("search_user_token"),
          timestamp: Date.now(),
          objectIDs: [e.objectID],
          queryID: e.queryID,
          positions: [e.showIndex]
        }
      ]
    })
  });
}
function pe(e) {
  if (!e || e === "")
    return;
  const t = "queryList", r = localStorage.getItem(t);
  let o;
  try {
    r && (o = JSON.parse(r)), o || (o = []), o.includes(e) || (o.unshift(e), o.length > 4 && o.pop()), localStorage.setItem(t, JSON.stringify(o));
  } catch {
  }
}
function ge() {
  if (typeof window > "u")
    return "";
  const e = /[?&]text=([^&#]*)/.exec(window.location.search);
  return e ? decodeURIComponent(e[1].replace(/\+/g, " ")) : "";
}
function de(e) {
  if (typeof e == "string")
    return `?text=${encodeURIComponent(e)}`;
  let t = `?text=${encodeURIComponent(e.query)}`;
  return e.category && (t += `&f_categories=${encodeURIComponent(e.category)}`), t;
}
function fe({
  onSubmit: e,
  algoliaSuggestionIndex: t,
  downshiftEnvironment: r
}) {
  const { t: o } = d("ig-search-bar"), [s, a] = J(ge), l = N("abtest_xtd_autosug-ew_4");
  function h() {
    a("");
  }
  const p = le();
  function m(c) {
    typeof c != "string" && pe(c.query), e(c);
  }
  return /* @__PURE__ */ g(
    q,
    {
      method: "get",
      action: o("/search"),
      onSubmit: (c) => {
        c.preventDefault(), m(s);
      },
      noValidate: !0,
      children: [
        /* @__PURE__ */ g(
          E,
          {
            searchClient: p,
            indexName: t,
            children: [
              /* @__PURE__ */ n(G, { clickAnalytics: !0, hitsPerPage: l === "A-control" ? 4 : 6 }),
              /* @__PURE__ */ n(
                se,
                {
                  value: s,
                  onSelect: (c) => m(c),
                  setValue: a,
                  downshiftEnvironment: r
                }
              )
            ]
          }
        ),
        s && /* @__PURE__ */ n(
          me,
          {
            onClick: () => h(),
            title: o("ClearResults"),
            "aria-label": o("ClearResults"),
            type: "reset",
            children: /* @__PURE__ */ n(he, { "aria-hidden": "true", focusable: "false" })
          }
        ),
        /* @__PURE__ */ n(R, {})
      ]
    }
  );
}
const me = ({
  children: e,
  ...t
}) => /* @__PURE__ */ n(
  "button",
  {
    ...t,
    className: w(
      "absolute right-[45px] top-1/2 z-[500] size-6 -translate-y-1/2 cursor-pointer rounded-full text-center",
      "grid place-items-center border-0 p-0 transition-shadow",
      "hover:shadow-[0_3px_7px_rgba(0,0,0,0.25)]",
      "focus:rounded-full focus:shadow-[0_0_0_3px_var(--tw-shadow-color)]"
    ),
    children: e
  }
), he = (e) => /* @__PURE__ */ n(
  Q,
  {
    className: "static mt-0.5 size-[20px] stroke-[3px] text-ignew-neutral-500",
    ...e
  }
);
function Re({
  downshiftEnvironment: e
}) {
  const t = b(), { t: r } = d("ig-search-bar"), o = t.algoliaSuggestionIndex ? t.algoliaSuggestionIndex : r("algoliaSuggestionIndex");
  function s(a) {
    typeof a != "string" && (U({
      type: "click_search_suggestions",
      data: {
        user_selected_value: a.query,
        position: a.showIndex,
        showsCategory: !!a.category
      }
    }), ue(
      a,
      t.algoliaApplicationId,
      t.suggestionApiKey,
      o
    ));
    const l = `${r("/search")}${de(a)}`;
    window.location.href = l;
  }
  return /* @__PURE__ */ n(
    fe,
    {
      onSubmit: (a) => s(a),
      algoliaSuggestionIndex: o,
      downshiftEnvironment: e
    }
  );
}
function ze() {
  const { t: e } = d("ig-search-bar");
  return /* @__PURE__ */ g(
    q,
    {
      method: "get",
      action: e("/search"),
      onSubmit: (t) => {
        t.preventDefault();
      },
      noValidate: !0,
      children: [
        /* @__PURE__ */ n(
          A,
          {
            type: "search",
            name: "text",
            autoComplete: "off",
            autoCapitalize: "off",
            autoCorrect: "off",
            required: !0
          }
        ),
        /* @__PURE__ */ n(R, {})
      ]
    }
  );
}
export {
  ze as LoadingSearchBar,
  Re as MainSearchBar,
  fe as SearchBar,
  pe as addQueryResultToLocalStorage,
  ge as getQuerySearchInitialValue,
  de as getSearchUrl,
  ue as sendResultToAlgolia
};
