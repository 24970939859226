import { jsx as e, jsxs as p } from "react/jsx-runtime";
import { forwardRef as i } from "react";
import { cn as s } from "@intergamma/common/cn";
import * as n from "@radix-ui/react-popover";
import { DropdownRoot as m } from "./DropdownRoot.js";
function g({
  trigger: a,
  children: t,
  align: o = "center",
  ...d
}) {
  return /* @__PURE__ */ p(m, { ...d, children: [
    a,
    /* @__PURE__ */ e(l, { align: o, children: t })
  ] });
}
const _ = i(
  ({ children: a, withBackdrop: t, className: o, ...d }, r) => /* @__PURE__ */ e(
    n.Content,
    {
      ...d,
      ref: r,
      className: s(
        "m-[0.625rem_0] w-[100vw] rounded-[0.8rem] bg-white",
        "will-change-[transform,opacity]",
        "md:max-w-[730px]",
        "lg:max-w-[960px]",
        "xl:max-w-[1200px]",
        "data-[state=open]:data-[side=top]:animate-dropdown-slide-down-and-fade",
        "data-[state=open]:data-[side=right]:animate-dropdown-slide-left-and-fade",
        "data-[state=open]:data-[side=bottom]:animate-dropdown-slide-up-and-fade",
        "data-[state=open]:data-[side=left]:animate-dropdown-slide-right-and-fade",
        "!z-20",
        t ? "!shadow-[0_10px_38px_-10px_hsla(206,22%,7%,0.35),_0_10px_20px_-15px_hsla(206,22%,7%,0.2),0_0_0_999em_rgba(0,0,0,0.3),0_1px_4px_rgba(0,0,0,0.3)]" : "!shadow-[0_10px_38px_-10px_hsla(206,22%,7%,0.35),_0_10px_20px_-15px_hsla(206,22%,7%,0.2)]",
        o ?? ""
      ),
      children: a
    }
  )
);
function x({ children: a, container: t, ...o }) {
  return /* @__PURE__ */ e(n.Portal, { container: t, children: /* @__PURE__ */ e(_, { ...o, children: a }) });
}
const l = x;
export {
  g as Dropdown,
  l as DropdownContent
};
