import { forwardRef, type SVGProps } from "react";

import { cn } from "@intergamma/common/cn";

export interface ChevronRightIconProps extends SVGProps<SVGSVGElement> {}

export const ChevronRight = forwardRef<SVGSVGElement, ChevronRightIconProps>(
  ({ className, ...props }, ref) => (
    <>
      <svg
        className={cn("hidden gamma:inline", className)}
        width="24"
        height="24"
        viewBox="0 0 24 25"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        ref={ref}
        {...props}
      >
        <path
          d="M11.0518 7.0927L16.7589 12.7998L11.0518 18.5069C10.6613 18.8974 10.0281 18.8974 9.63762 18.5069C9.2471 18.1164 9.2471 17.4832 9.63762 17.0927L13.9305 12.7998L9.63762 8.50691C9.2471 8.11639 9.2471 7.48322 9.63762 7.0927C10.0281 6.70217 10.6613 6.70217 11.0518 7.0927Z"
          fill="currentColor"
        />
      </svg>

      <svg
        className={cn("hidden karwei:inline", className)}
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        ref={ref}
        {...props}
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M17.0479 11.6659L10.1384 18.7069L9.42469 18.0065L15.6403 11.6725L9.42801 5.46028L10.1351 4.75317L17.0479 11.6659Z"
          fill="currentColor"
        />
      </svg>
    </>
  ),
);

(ChevronRight as React.FC).displayName = "ChevronRight";
