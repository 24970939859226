import { forwardRef, type SVGProps } from "react";

import { cn } from "@intergamma/common/cn";

export interface EyeHiddenIconProps extends SVGProps<SVGSVGElement> {}

export const EyeHidden = forwardRef<SVGSVGElement, EyeHiddenIconProps>(
  ({ className, ...props }, ref) => (
    <>
      <svg
        className={cn("hidden gamma:block", className)}
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        ref={ref}
        {...props}
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4.57736 3.51692C4.58162 3.52139 4.58594 3.52582 4.59033 3.53021L19.7931 18.733C19.7976 18.7375 19.8022 18.7419 19.8068 18.7463C20.0866 19.0401 20.0823 19.5052 19.7938 19.7937C19.5009 20.0866 19.026 20.0866 18.7331 19.7937L16.77 17.8306C15.4423 18.6808 13.8452 19.2954 12 19.2954C8.83673 19.2954 6.40222 17.4892 4.80512 15.7856C3.99973 14.9265 3.38465 14.0703 2.97082 13.4295C2.76345 13.1084 2.60538 12.8395 2.49813 12.6489C2.44447 12.5535 2.40345 12.4775 2.37524 12.4242C2.36114 12.3976 2.35023 12.3766 2.34255 12.3616L2.33344 12.3438L2.3307 12.3384L2.32979 12.3366L2.32918 12.3353L3 11.9999C2.32918 11.6645 2.32979 11.6633 2.32979 11.6633L2.3307 11.6615L2.33344 11.6561L2.34255 11.6382C2.35023 11.6233 2.36114 11.6023 2.37524 11.5756C2.40345 11.5223 2.44447 11.4464 2.49813 11.351C2.60538 11.1603 2.76345 10.8914 2.97082 10.5703C3.38465 9.92954 3.99973 9.07332 4.80512 8.21424C5.16459 7.8308 5.56648 7.44217 6.00956 7.0701L3.53033 4.59088C3.23744 4.29798 3.23744 3.82311 3.53033 3.53022C3.81872 3.24182 4.28355 3.23739 4.57736 3.51692ZM5.89943 9.24015C6.25654 8.85923 6.64869 8.48435 7.07449 8.13504L9.2663 10.3268C8.96762 10.8138 8.79541 11.3868 8.79541 11.9999C8.79541 13.7697 10.2301 15.2045 12 15.2045C12.6131 15.2045 13.186 15.0323 13.673 14.7336L15.68 16.7405C14.6065 17.3737 13.3749 17.7954 12 17.7954C9.436 17.7954 7.3705 16.3288 5.89943 14.7597C5.17073 13.9824 4.60967 13.2023 4.23088 12.6158C4.07234 12.3703 3.94659 12.16 3.85463 11.9999C3.94659 11.8398 4.07234 11.6296 4.23088 11.3841C4.60967 10.7976 5.17073 10.0174 5.89943 9.24015ZM10.2954 11.9999C10.2954 11.8066 10.3276 11.6207 10.3869 11.4475L12.5524 13.613C12.3792 13.6723 12.1933 13.7045 12 13.7045C11.0586 13.7045 10.2954 12.9413 10.2954 11.9999Z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.60932 5.42788L9.76653 6.58509C10.4614 6.3448 11.2069 6.20447 12 6.20447C14.564 6.20447 16.6295 7.671 18.1006 9.24015C18.8293 10.0174 19.3903 10.7976 19.7691 11.3841C19.9277 11.6296 20.0534 11.8398 20.1454 11.9999C20.0534 12.16 19.9277 12.3703 19.7691 12.6158C19.3903 13.2023 18.8293 13.9824 18.1006 14.7597C18.0749 14.787 18.0491 14.8144 18.0231 14.8417L19.084 15.9026C19.1214 15.8636 19.1584 15.8246 19.1949 15.7856C20.0003 14.9265 20.6154 14.0703 21.0292 13.4295C21.2366 13.1084 21.3946 12.8395 21.5019 12.6489C21.5555 12.5535 21.5966 12.4775 21.6248 12.4242C21.6389 12.3976 21.6498 12.3766 21.6574 12.3616L21.6666 12.3438L21.6693 12.3384L21.6702 12.3366C21.6702 12.3366 21.6708 12.3353 21 11.9999L21.6708 12.3353C21.7764 12.1242 21.7761 11.8751 21.6706 11.664L21.6693 11.6615L21.6666 11.6561L21.6574 11.6382C21.6498 11.6233 21.6389 11.6023 21.6248 11.5756C21.5966 11.5223 21.5555 11.4464 21.5019 11.351C21.3946 11.1603 21.2366 10.8914 21.0292 10.5703C20.6154 9.92954 20.0003 9.07332 19.1949 8.21424C17.5978 6.51066 15.1633 4.70447 12 4.70447C10.7546 4.70447 9.62217 4.98444 8.60932 5.42788ZM21.6706 11.664L21 11.9999C21.6708 11.6645 21.6706 11.664 21.6706 11.664Z"
          fill="currentColor"
        />
        <path
          d="M15.2044 12.023C15.2045 12.0153 15.2045 12.0076 15.2045 11.9999C15.2045 10.2301 13.7698 8.79538 12 8.79538C11.9923 8.79538 11.9846 8.79541 11.9769 8.79546L15.2044 12.023Z"
          fill="currentColor"
        />
        <path
          d="M3 11.9999C2.32918 12.3353 2.32918 12.3353 2.32918 12.3353C2.22361 12.1242 2.22361 11.8757 2.32918 11.6645L3 11.9999Z"
          fill="currentColor"
        />
      </svg>
      <svg
        className={cn("hidden karwei:block", className)}
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        ref={ref}
        {...props}
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4.57736 3.51692C4.58162 3.52139 4.58594 3.52582 4.59033 3.53021L19.7931 18.733C19.7976 18.7375 19.8022 18.7419 19.8068 18.7463C20.0866 19.0401 20.0823 19.5052 19.7938 19.7937C19.5009 20.0866 19.026 20.0866 18.7331 19.7937L16.77 17.8306C15.4423 18.6808 13.8452 19.2954 12 19.2954C8.83673 19.2954 6.40222 17.4892 4.80512 15.7856C3.99973 14.9265 3.38465 14.0703 2.97082 13.4295C2.76345 13.1084 2.60538 12.8395 2.49813 12.6489C2.44447 12.5535 2.40345 12.4775 2.37524 12.4242C2.36114 12.3976 2.35023 12.3766 2.34255 12.3616L2.33344 12.3438L2.3307 12.3384L2.32979 12.3366L2.32918 12.3353L3 11.9999C2.32918 11.6645 2.32979 11.6633 2.32979 11.6633L2.3307 11.6615L2.33344 11.6561L2.34255 11.6382C2.35023 11.6233 2.36114 11.6023 2.37524 11.5756C2.40345 11.5223 2.44447 11.4464 2.49813 11.351C2.60538 11.1603 2.76345 10.8914 2.97082 10.5703C3.38465 9.92954 3.99973 9.07332 4.80512 8.21424C5.16459 7.8308 5.56648 7.44217 6.00956 7.0701L3.53033 4.59088C3.23744 4.29798 3.23744 3.82311 3.53033 3.53022C3.81872 3.24182 4.28355 3.23739 4.57736 3.51692ZM5.89943 9.24015C6.25654 8.85923 6.64869 8.48435 7.07449 8.13504L9.2663 10.3268C8.96762 10.8138 8.79541 11.3868 8.79541 11.9999C8.79541 13.7697 10.2301 15.2045 12 15.2045C12.6131 15.2045 13.186 15.0323 13.673 14.7336L15.68 16.7405C14.6065 17.3737 13.3749 17.7954 12 17.7954C9.436 17.7954 7.3705 16.3288 5.89943 14.7597C5.17073 13.9824 4.60967 13.2023 4.23088 12.6158C4.07234 12.3703 3.94659 12.16 3.85463 11.9999C3.94659 11.8398 4.07234 11.6296 4.23088 11.3841C4.60967 10.7976 5.17073 10.0174 5.89943 9.24015ZM10.2954 11.9999C10.2954 11.8066 10.3276 11.6207 10.3869 11.4475L12.5524 13.613C12.3792 13.6723 12.1933 13.7045 12 13.7045C11.0586 13.7045 10.2954 12.9413 10.2954 11.9999Z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.60932 5.42788L9.76653 6.58509C10.4614 6.3448 11.2069 6.20447 12 6.20447C14.564 6.20447 16.6295 7.671 18.1006 9.24015C18.8293 10.0174 19.3903 10.7976 19.7691 11.3841C19.9277 11.6296 20.0534 11.8398 20.1454 11.9999C20.0534 12.16 19.9277 12.3703 19.7691 12.6158C19.3903 13.2023 18.8293 13.9824 18.1006 14.7597C18.0749 14.787 18.0491 14.8144 18.0231 14.8417L19.084 15.9026C19.1214 15.8636 19.1584 15.8246 19.1949 15.7856C20.0003 14.9265 20.6154 14.0703 21.0292 13.4295C21.2366 13.1084 21.3946 12.8395 21.5019 12.6489C21.5555 12.5535 21.5966 12.4775 21.6248 12.4242C21.6389 12.3976 21.6498 12.3766 21.6574 12.3616L21.6666 12.3438L21.6693 12.3384L21.6702 12.3366C21.6702 12.3366 21.6708 12.3353 21 11.9999L21.6708 12.3353C21.7764 12.1242 21.7761 11.8751 21.6706 11.664L21.6693 11.6615L21.6666 11.6561L21.6574 11.6382C21.6498 11.6233 21.6389 11.6023 21.6248 11.5756C21.5966 11.5223 21.5555 11.4464 21.5019 11.351C21.3946 11.1603 21.2366 10.8914 21.0292 10.5703C20.6154 9.92954 20.0003 9.07332 19.1949 8.21424C17.5978 6.51066 15.1633 4.70447 12 4.70447C10.7546 4.70447 9.62217 4.98444 8.60932 5.42788ZM21.6706 11.664L21 11.9999C21.6708 11.6645 21.6706 11.664 21.6706 11.664Z"
          fill="currentColor"
        />
        <path
          d="M15.2044 12.023C15.2045 12.0153 15.2045 12.0076 15.2045 11.9999C15.2045 10.2301 13.7698 8.79538 12 8.79538C11.9923 8.79538 11.9846 8.79541 11.9769 8.79546L15.2044 12.023Z"
          fill="currentColor"
        />
        <path
          d="M3 11.9999C2.32918 12.3353 2.32918 12.3353 2.32918 12.3353C2.22361 12.1242 2.22361 11.8757 2.32918 11.6645L3 11.9999Z"
          fill="currentColor"
        />
      </svg>
    </>
  ),
);

(EyeHidden as React.FC).displayName = "EyeHidden";
