import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

export function useAlternateUrls() {
  const { pathname } = useLocation();
  const {
    t,
    i18n: { language },
  } = useTranslation("urls");

  if (language === "nl-BE") {
    const alternateURL: string = NLtoFRMapping.get(pathname) ?? t("/myaccount");

    return {
      nl: pathname,
      fr: alternateURL,
    };
  }

  const alternateURL: string = FRToNLMapping.get(pathname) ?? t("/myaccount");

  return {
    nl: alternateURL,
    fr: pathname,
  };
}

const FRToNLMapping = new Map([
  ["/my/fr/", "/my/nl/"],
  ["/my/fr/se-connecter", "/my/nl/inloggen"],
  ["/my/fr/se-deconnecter", "/my/nl/uitloggen"],
  ["/my/fr/creer-un-compte", "/my/nl/account-aanmaken"],
  ["/my/fr/creer-un-compte-confirme", "/my/nl/account-aanmaken-bevestigd"],
  ["/my/fr/mes-donnees", "/my/nl/mijn-gegevens"],
  [
    "/my/fr/mes-donnees/mes-donnes-personnelles",
    "/my/nl/mijn-gegevens/persoonlijke-gegevens",
  ],
  [
    "/my/fr/mes-donnees/changer-le-mot-de-passe",
    "/my/nl/mijn-gegevens/wachtwoord-wijzigen",
  ],
  [
    "/my/fr/mes-donnees/changer-ladresse-mail",
    "/my/nl/mijn-gegevens/e-mailadres-wijzigen",
  ],
  ["/my/fr/mes-donnees/lettre-info", "/my/nl/mijn-gegevens/nieuwsbrief"],
  [
    "/my/fr/mes-donnees/carte-gamma-plus",
    "/my/nl/mijn-gegevens/gamma-plus-kaart",
  ],
  ["/my/fr/carte-cadeau", "/my/nl/cadeaukaart"],
  [
    "/my/fr/demande-de-suppression-de-mon-compte",
    "/my/nl/verzoek-tot-verwijderen-account",
  ],
  ["/my/fr/etat-de-la-commande", "/my/nl/bestelstatus-opvragen"],
  ["/my/fr/inscription-retour", "/my/nl/aanmelden-retour"],
  ["/my/fr/demander-nouveau-mot-de-passe", "/my/nl/nieuw-wachtwoord-aanvragen"],
  ["/my/fr/carte-gamma-plus/registre", "/my/nl/gamma-plus-kaart/registreren"],
  ["/my/fr/carte-gamma-plus/activer", "/my/nl/gamma-plus-kaart/activeer"],
  ["/my/fr/inscrire-lettre-info", "/my/nl/aanmelden-nieuwsbrief"],
  ["/my/fr/campagne", "/my/nl/campagne"],
  ["/my/fr/service-en-contact/contact", "/my/nl/service-en-contact/contact"],
  [
    "/my/fr/GAMMAplus-liste-transactions",
    "/my/nl/loyalty-transactie-overzicht",
  ],
  ["/my/fr/mes-achats", "/my/nl/mijn-aankopen"],
]);

const NLtoFRMapping = new Map(
  Array.from(FRToNLMapping, (entry) => [entry[1], entry[0]]),
);
