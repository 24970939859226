import { Suspense } from "react";

import { LoadingHeader } from "@intergamma/header";
import { MainLegalFooter } from "@intergamma/legal-footer";
import { Footer } from "@intergamma/footer";

import { SessionDataProvider } from "./features/session-data/SessionDataContext";
import { Header } from "./layouts/Header";
import { LoadingPageLayout } from "./layouts/LoadingPageLayout";
import { Routes } from "./Routes";
import { ScrollToTop } from "./components/ScrollToTop";

export function App() {
  return (
    <Suspense fallback={<LoadingHeader />}>
      <SessionDataProvider>
        <Header />
        <Suspense fallback={<LoadingPageLayout />}>
          <ScrollToTop />
          <Routes />
        </Suspense>
        <Footer>
          <MainLegalFooter />
        </Footer>
      </SessionDataProvider>
    </Suspense>
  );
}
