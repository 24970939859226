import { jsx as t } from "react/jsx-runtime";
import { cn as n } from "@intergamma/common/cn";
const p = "div", s = ({
  as: o,
  children: m,
  className: x
}) => /* @__PURE__ */ t(
  o ?? p,
  {
    className: n(
      "mx-auto px-4 md:max-w-[768px] md:px-6 lg:max-w-[1024px] lg:px-8 xl:max-w-[1264px]",
      x
    ),
    children: m
  }
);
export {
  s as Container
};
