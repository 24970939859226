import { jsx as o, jsxs as a } from "react/jsx-runtime";
import { WithoutDevices as s } from "@intergamma/devices";
import { useLegacyTheme as n } from "@intergamma/theme/provider";
import { useTranslation as r } from "react-i18next";
import { D as c, C as m, g, N as f } from "./NavigationFooterInner-DKtPNgJr.js";
function u() {
  const { t: e } = r("ig-footer"), { name: t } = n();
  return /* @__PURE__ */ o(c, { t: e, legacyThemeBrand: t });
}
function l() {
  const { t: e } = r("ig-footer");
  return /* @__PURE__ */ o(m, { t: e });
}
function p() {
  const { name: e } = n(), { t } = r("ig-footer"), i = g({
    t,
    legacyThemeBrand: e
  });
  return /* @__PURE__ */ o(f, { navigation: i });
}
function N({ children: e }) {
  return /* @__PURE__ */ o(s, { devices: ["app"], children: /* @__PURE__ */ a("footer", { className: "leading-[1.6rem] antialiased [text-rendering:optimizeLegibility]", children: [
    /* @__PURE__ */ o(u, {}),
    /* @__PURE__ */ o(l, {}),
    /* @__PURE__ */ o(p, {}),
    e
  ] }) });
}
export {
  N as Footer
};
