import { lazy } from "react";

import { useTranslation } from "react-i18next";
import { Navigate, RouteObject } from "react-router-dom";

import { Layout } from "~/layouts/Layout";

import { NavigateWithParams } from "~/components/NavigateWithParams";

import { routeMapper } from "~/helpers/route-mapper";

import { useBaseConfig } from "~/config/MyAccountConfigContext";

const LoginPage = lazy(() => import("~/pages/LoginPage"));
const NewsletterPreferencesPageLoggedOut = lazy(
  () => import("~/pages/profile/NewsletterPreferencesPageLoggedOut"),
);

export function useGuestModeRoutes(): RouteObject[] {
  const { t } = useTranslation("urls");
  const { country } = useBaseConfig();

  return [
    {
      element: <Layout whiteBg />,
      children: [
        {
          path: t("/login"),
          element: <LoginPage />,
        },
        {
          path: t("/sign-up"),
          element: <NavigateWithParams to={t("/login")} replace />,
        },
      ].map((route) => routeMapper(country, route)),
    },
    {
      element: <Layout />,
      children: [
        {
          path: t("/forgot-password"),
          element: <Navigate to={t("/login")} replace />,
        },
        {
          path: t("/profile/newsletter"),
          element: <NewsletterPreferencesPageLoggedOut />,
        },

        // Redirects for Authenticated pages
        {
          path: t("/myaccount"),
          element: <Navigate to={t("/login")} replace />,
        },
        {
          path: t("/profile/personal-information"),
          element: <Navigate to={t("/login")} replace />,
        },
        {
          path: t("/profile/change-password"),
          element: <Navigate to={t("/login")} replace />,
        },
        {
          path: t("/profile/change-email"),
          element: <Navigate to={t("/login")} replace />,
        },
        {
          path: t("/profile/loyaltycards"),
          element: <Navigate to={t("/login")} replace />,
        },
        {
          path: t("/orders"),
          children: [
            {
              index: true,
              element: <Navigate to={t("/login")} replace />,
            },
            {
              path: ":code",
              element: <Navigate to={t("/login")} replace />,
            },
          ],
        },
        {
          path: t("/purchases"),
          children: [
            {
              element: (
                <Navigate to={`${t("/login")}?ref=/purchases`} replace />
              ),
              index: true,
            },
            { path: ":code", element: <Navigate to={t("/login")} replace /> },
          ],
        },
        {
          path: `${t("/receipts")}`,
          element: <Navigate to={t("/login")} replace />,
        },
        {
          path: `${t("/receipts")}/:receiptId`,
          element: <Navigate to={t("/login")} replace />,
        },
        {
          path: t("/loyaltyMutations"),
          element: <Navigate to={t("/login")} replace />,
        },
        {
          path: t("/remove-account"),
          element: <Navigate to={t("/login")} replace />,
        },
      ].map((route) => routeMapper(country, route)),
    },
  ];
}
