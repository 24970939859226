import { forwardRef, type SVGProps } from "react";

export interface LoyaltyCardIconProps extends SVGProps<SVGSVGElement> {}

export const LoyaltyCard = forwardRef<SVGSVGElement, LoyaltyCardIconProps>(
  (props, ref) => (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      ref={ref}
      {...props}
    >
      <path
        d="M16.25 10.5C15.8358 10.5 15.5 10.8358 15.5 11.25C15.5 11.6642 15.8358 12 16.25 12H18.75C19.1642 12 19.5 11.6642 19.5 11.25C19.5 10.8358 19.1642 10.5 18.75 10.5H16.25Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.75 7C0.75 4.92893 2.42893 3.25 4.5 3.25H19.5C21.5711 3.25 23.25 4.92893 23.25 7V17C23.25 19.0711 21.5711 20.75 19.5 20.75H4.5C2.42893 20.75 0.75 19.0711 0.75 17V7ZM4.5 4.75C3.25736 4.75 2.25 5.75736 2.25 7V14H21.75V7C21.75 5.75736 20.7426 4.75 19.5 4.75H4.5ZM2.25 17V15.5H21.75V17C21.75 18.2426 20.7426 19.25 19.5 19.25H4.5C3.25736 19.25 2.25 18.2426 2.25 17Z"
        fill="currentColor"
      />
    </svg>
  ),
);

(LoyaltyCard as React.FC).displayName = "LoyaltyCard";
