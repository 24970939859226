import { getDomain as y } from "@intergamma/common/formula";
import w from "js-cookie";
import { Amplify as I } from "aws-amplify";
import { useMemo as L, useState as C } from "react";
import { dispatchAdobeEvent as m } from "@intergamma/adobe-tracking";
import { signIn as P, confirmSignIn as v } from "aws-amplify/auth";
import { useQueryClient as A } from "@tanstack/react-query";
import { useTranslation as k } from "react-i18next";
import { l as g } from "./datadog-logging-Ds9IeYxC.js";
import { useConfig as T } from "@intergamma/config";
import { c as F } from "./index-C2EW4nef.js";
const M = (e) => {
  const o = typeof document < "u" && document.location.href.match(/\/\/([^:/]+)/), r = (o ? o[1] : "").indexOf("acceptatie") < 0;
  switch (e) {
    case "gamma_be":
      return {
        userPoolId: r ? "eu-west-1_KwrkK0ufX" : "eu-west-1_j0JUmjRMW",
        // GAMMA BE ACC - USER POOL ID
        userPoolClientId: r ? "6bi3hbinnkcq1h12ctlige9f08" : "1te9tbna7h0sd6bemnevublnts"
        // GAMMA BE ACC - CLIENT ID
      };
    case "karwei_nl":
      return {
        userPoolId: r ? "eu-west-1_Ogv9zM2Zp" : "eu-west-1_AzWkTXNK8",
        // KARWEI ACC - USER POOL ID
        userPoolClientId: r ? "6lqki7p47e0q3etbn87pvb56op" : "7crtb2r6sfb9spi854qq0qcp1b"
        // KARWEI ACC - CLIENT ID
      };
    case "gamma_nl":
    default:
      return {
        userPoolId: r ? "eu-west-1_OHzUYP2T4" : "eu-west-1_Sm2PRyfmS",
        // GAMMA NL ACC - USER POOL ID
        userPoolClientId: r ? "78km7bhfrsa50ajiebbi8ng65q" : "64pji0q6h1armolasivbn51ftq"
        // GAMMA NL ACC - CLIENT ID
      };
  }
};
function N(e) {
  const o = w.get();
  Object.keys(o).forEach((t) => {
    t.startsWith("CognitoIdentityServiceProvider") && w.remove(t, { path: "/", domain: e });
  });
}
function K(e) {
  N(y(e)), I.configure({
    Auth: {
      Cognito: M(e)
    }
  });
}
function R(e) {
  const o = F(e, {
    withCredentials: !0
  });
  return {
    async getGatewayCSRF() {
      return (await o.get(
        "/gateway/session"
      )).csrfToken;
    },
    async login(t) {
      const r = await this.getGatewayCSRF();
      return o.post("/gateway/addcard/login", t, {
        headers: {
          "X-IG-CSRF-TOKEN": r
        }
      });
    }
  };
}
const U = () => {
  const { MYACCOUNT_URL: e } = T(), o = L(
    () => R(e),
    [e]
  );
  return {
    legacyLogin: (r) => new Promise((s, f) => {
      o.login(r).then(() => s()).catch(() => f());
    })
  };
};
function X(e = "loginHeader", o = !0) {
  const { t } = k(["ig-header", "register", "login-form"]), [r, s] = C(!1), [f, p] = C(), h = A(), { legacyLogin: S } = U();
  return {
    authenticate: ({
      body: a,
      disableAdobeEvent: _
    }) => (s(!0), a.password ? new Promise((d, u) => {
      P({
        username: a.customerId,
        password: a.password,
        options: {
          authFlowType: "USER_PASSWORD_AUTH"
        }
      }).then(() => (_ || m({
        type: e === "loginHeader" ? "login_header" : "login",
        data: {
          user_selected_element: "password",
          kdbid: a.customerId
        }
      }), s(!1), o && h.resetQueries(), g.info("Login", {
        details: "Successful"
      }), d())).catch((l) => {
        const { name: i, message: n } = l;
        S({ email: a.email, password: a.password }).then(() => (s(!1), o && h.resetQueries(), g.info("Legacy login", {
          details: "Successful"
        }), d)).catch(() => {
          g.error("Login", {
            details: "Failed",
            errorDetails: `${i}: ${n}`
          }), m({
            type: e === "loginHeader" ? "login_error_header" : "login_error",
            data: {
              user_message_type: `${i}: ${n}`
            }
          });
          let c = t("ig-header:loginSomethingWentWrong");
          return i === "NotAuthorizedException" && (c = t("ig-header:loginInvalidPassword"), n === "Password attempts exceeded" && (c = t("ig-header:loginTooManyAttempts"))), i === "UserNotFoundException" && (c = t("ig-header:loginUserNotFound")), s(!1), p(c), u();
        });
      });
    }) : new Promise((d, u) => {
      v({
        challengeResponse: a.verificationCode ?? ""
      }).then((l) => {
        const { isSignedIn: i, nextStep: n } = l;
        return s(!1), i ? (g.info("Login via code", {
          details: "Successful"
        }), _ || m({
          type: e === "loginHeader" ? "login_header" : "login",
          data: {
            user_selected_element: "code",
            kdbid: a.customerId
          }
        }), o && h.resetQueries(), d()) : (n.signInStep === "CONFIRM_SIGN_IN_WITH_CUSTOM_CHALLENGE" && (g.warn("Login via code", {
          details: "Invalid code"
        }), p(t("register:errorNotValidCode"))), u());
      }).catch((l) => {
        const { name: i, message: n } = l;
        m({
          type: e === "loginHeader" ? "login_error_header" : "login_error",
          data: {
            user_message_type: `${i}: ${n}`
          }
        });
        let c = t("ig-header:loginSomethingWentWrong");
        return i === "NotAuthorizedException" ? (c = t("login-form:loginCodeMaxAttempts"), g.error("Login via code", {
          details: "Reached max attempts",
          errorDetails: `${i}: ${n}`
        })) : g.error("Login via code", {
          details: "Failed",
          errorDetails: `${i}: ${n}`
        }), s(!1), p(c), u();
      });
    })),
    error: f,
    isLoading: r
  };
}
export {
  K as c,
  M as g,
  X as u
};
