import { forwardRef, type SVGProps } from "react";

import { cn } from "@intergamma/common/cn";

export interface ChevronDownIconProps extends SVGProps<SVGSVGElement> {}

export const ChevronDown = forwardRef<SVGSVGElement, ChevronDownIconProps>(
  ({ className, ...props }, ref) => (
    <>
      <svg
        className={cn("hidden gamma:inline", className)}
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        ref={ref}
        {...props}
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12 15.4053L6.46967 9.87494C6.17678 9.58205 6.17678 9.10718 6.46967 8.81428C6.76256 8.52139 7.23744 8.52139 7.53033 8.81428L12 13.284L16.4697 8.81428C16.7626 8.52139 17.2374 8.52139 17.5303 8.81428C17.8232 9.10718 17.8232 9.58205 17.5303 9.87494L12 15.4053Z"
          fill="currentColor"
          stroke="currentColor"
          strokeWidth="0.5"
          strokeLinecap="round"
        />
      </svg>
      <svg
        className={cn("hidden karwei:inline", className)}
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        ref={ref}
        {...props}
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12.1283 15.7168L5.08726 8.80738L5.78766 8.09364L12.1216 14.3092L18.3339 8.09696L19.041 8.80406L12.1283 15.7168Z"
          fill="currentColor"
        />
      </svg>
    </>
  ),
);

(ChevronDown as React.FC).displayName = "ChevronDown";
