import {
  MouseEventHandler,
  forwardRef,
  useEffect,
  useId,
  type HTMLProps,
  type ReactNode,
} from "react";

import { cn } from "@intergamma/common/cn";

import { Cross } from "../icons/Cross";

export const Snackbar = forwardRef<
  HTMLDivElement,
  HTMLProps<HTMLDivElement> & {
    children: ReactNode;
    title?: String;
    icon?: ReactNode;
    variant: "info" | "success" | "warning" | "error";
    onClose?: MouseEventHandler<HTMLButtonElement>;
    onMount?: () => void;
  }
>(
  // eslint-disable-next-line prefer-arrow-callback
  function Snackbar(
    { children, title, icon, variant, onClose, onMount, className, ...props },
    ref,
  ) {
    const id = useId();

    useEffect(() => {
      onMount?.();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
      <div
        ref={ref}
        role="alert"
        className={cn(
          "flex flex-row items-start gap-2 p-4 text-base/6 gamma:rounded-lg",
          variant === "info" &&
            "bg-ignew-functional-primary-25 text-brand-primary",
          variant === "success" &&
            "text-ignew-functional-secondary-800 gamma:bg-ignew-functional-conversion-50 karwei:bg-ignew-functional-secondary-50",
          variant === "warning" &&
            "text-ignew-functional-tertiary-800 gamma:bg-ignew-functional-tertiary-50 karwei:bg-ignew-functional-tertiary-100",
          variant === "error" &&
            "bg-ignew-functional-quaternary-50 text-ignew-functional-quaternary-700",
          className,
        )}
        aria-labelledby={`snackbar-${id}`}
        {...props}
      >
        {icon && <div>{icon}</div>}
        <div
          className="flex flex-1 flex-col gap-1 leading-6"
          id={`snackbar-${id}`}
        >
          {title && <h2 className="font-bold">{title}</h2>}
          {children}
        </div>
        {typeof onClose !== "undefined" && (
          <button
            className={cn(
              "rounded-full bg-transparent fill-[currentColor]",
              "outline-none ring-offset-1 focus-visible:ring-3 focus-visible:ring-ignew-functional-focus-400",
            )}
            type="button"
            // @todo Should be translated
            aria-label="Sluiten"
            onClick={onClose}
          >
            <Cross aria-hidden="true" />
          </button>
        )}
      </div>
    );
  },
);
