import { createHttpClient as T } from "@intergamma/account/login";
import { B as he, a as ge, b as pe, c as be, d as ye, e as ve, U as k, S as X, t as J, T as V, f as Ne } from "./LoadingHeader-DKlK1fs-.js";
import { C as Nt, F as St, H as wt, I as xt, g as Ct, L as kt, h as At, i as Ot, j as Lt } from "./LoadingHeader-DKlK1fs-.js";
import { useTranslation as f } from "react-i18next";
import { useTranslation as Dt } from "react-i18next";
import { jsx as n, jsxs as l } from "react/jsx-runtime";
import { Container as Se } from "@intergamma/container";
import { useState as g, useEffect as v, useRef as U, useMemo as C, useCallback as Q, lazy as O, Suspense as R, Children as we, isValidElement as xe, cloneElement as Ce } from "react";
import { useLegacyTheme as F, useFormula as ke } from "@intergamma/theme/provider";
import { useQuery as L, keepPreviousData as Ae, useQueryClient as Oe, useMutation as Le } from "@tanstack/react-query";
import { DialogRoot as Ee, UnstyledDialogTrigger as De, DrawerContent as Ie, DialogTitle as Be, DialogTrigger as B, DialogBottomSheet as Z, DialogExtended as ee } from "@intergamma/dialog";
import { DropdownRoot as z, DropdownTrigger as Y, DropdownContent as $ } from "@intergamma/dropdown";
import { cn as h } from "@intergamma/common/cn";
import { MapPin as Me, LogOut as Te, ChevronRight as ne, ArrowLeft as _e, X as Ue, ChevronDown as Re, ChevronUp as Fe, Star as ze } from "react-feather";
import { Anchor as S } from "@intergamma/anchor";
import { cn as D } from "@intergamma/common";
import { useConfig as N } from "@intergamma/config";
import { MainSearchBar as q, SearchBar as Ye } from "@intergamma/search-bar";
import { ListItem as A } from "@intergamma/list";
import { dispatchAdobeEvent as $e } from "@intergamma/adobe-tracking";
import { localStorage as H } from "@intergamma/storage";
function te(e) {
  var i;
  const r = T(e.SHOPFRONT_URL, {
    withCredentials: !0
  }), t = ((i = e.locales) == null ? void 0 : i[0].split(/[_-]/)[0]) ?? "nl";
  return {
    getCategories(o) {
      return r.get(e.country === "be" ? `/${t}/resources/menu/${o}` : `/resources/menu/${o}`).then(
        (s) => s.map(
          (a) => ({
            ...a,
            type: "category",
            dataUrl: qe(a.dataUrl)
          })
        )
      );
    }
  };
}
function qe(e) {
  return e.replace("https://www.acceptatie.gamma.nl/resources/menu/", "").replace("https://www.gamma.nl/resources/menu/", "").replace("https://www.acceptatie.gamma.be/nl/resources/menu/", "").replace("https://www.acceptatie.gamma.be/fr/resources/menu/", "").replace("https://www.gamma.be/nl/resources/menu/", "").replace("https://www.gamma.be/fr/resources/menu/", "").replace("https://www.acceptatie.karwei.nl/resources/menu/", "").replace("https://www.karwei.nl/resources/menu/", "");
}
function Ke({ category: e, onSelect: r }) {
  return /* @__PURE__ */ n(
    "li",
    {
      className: h(
        "m-0 block h-16 break-inside-avoid border-b gamma:border-ignew-neutral-100 karwei:border-[#dce1e8] lg:flex lg:h-10 lg:items-center lg:border-none",
        e.divider && "mb-10 lg:mb-0"
      ),
      children: /* @__PURE__ */ l(
        "a",
        {
          className: h(
            "group m-0 flex size-full grow cursor-pointer items-center rounded-lg border-none bg-transparent py-4 text-left text-[1em] leading-[1em] text-brand-primary no-underline",
            "hover:underline",
            "outline-none ring-offset-1 focus-visible:ring-3 focus-visible:ring-ignew-functional-focus-400",
            "lg:w-auto lg:grow-0 lg:p-0",
            "justify-between px-4 lg:justify-normal"
          ),
          href: e.url,
          onClick: (t) => {
            e.hasSubCategories && (r(e), t.preventDefault());
          },
          children: [
            /* @__PURE__ */ l("div", { className: "flex gap-2", children: [
              e.icon === "store" && /* @__PURE__ */ n(Me, { className: "!size-4 align-middle" }),
              e.icon === "logout" && /* @__PURE__ */ n(Te, { className: "align-middle" }),
              e.name
            ] }),
            e.hasSubCategories && /* @__PURE__ */ n(
              ne,
              {
                className: h(
                  "ml-1 size-6 text-ignew-neutral-300 transition motion-safe:group-hover:translate-x-[5px] lg:right-[inherit] lg:size-4 lg:text-brand-primary",
                  "karwei:!stroke-1 karwei:text-ignew-neutral-600"
                )
              }
            )
          ]
        }
      )
    }
  );
}
function Pe({ categories: e, onSelect: r }) {
  return /* @__PURE__ */ n("div", { className: "relative", children: /* @__PURE__ */ n("div", { className: "overflow-hidden lg:min-h-[205px]", children: /* @__PURE__ */ n("ul", { className: "list-none columns-1 gap-x-5 p-0 text-[1em] lg:mx-0 lg:columns-4 lg:px-5 lg:pb-5 lg:text-[0.9em]", children: e.map((t) => /* @__PURE__ */ n(
    Ke,
    {
      category: t,
      onSelect: r
    },
    t.uid
  )) }) }) });
}
function Qe({ lastBreadcrumb: e }) {
  const { t: r } = f("ig-header");
  return /* @__PURE__ */ n(
    "div",
    {
      className: D(
        "h-16 rounded-b-lg pb-[15px] lg:h-auto lg:px-5 lg:py-[15px] lg:text-75/normal gamma:lg:bg-[#f0f2f6] karwei:lg:bg-[#f1f4f8]",
        "border-b gamma:border-ignew-neutral-100 karwei:border-[#dce1e8]"
      ),
      children: /* @__PURE__ */ n(
        S,
        {
          className: "flex items-center p-4 no-underline hover:underline lg:p-0",
          href: e.url,
          children: e.uid === "root" || e.uid === "root-mobile" ? r("ShowEntireCatalog") : r("ShowAllIn", { name: e.name })
        }
      )
    }
  );
}
function He({
  breadcrumbs: e,
  onBack: r,
  onClose: t
}) {
  const { t: i } = f("ig-header"), o = e.length;
  return /* @__PURE__ */ l("div", { className: "mb-3 flex items-center gap-2 border-b border-ignew-neutral-100 p-4 md:mt-[10px] lg:mb-2 lg:border-none lg:p-0 lg:px-[20px] lg:pt-[10px]", children: [
    o >= 2 && /* @__PURE__ */ n(
      "button",
      {
        className: D(
          "grid size-8 cursor-pointer place-items-center rounded-full text-brand-primary lg:hidden",
          "outline-none ring-offset-1 focus-visible:ring-3 focus-visible:ring-ignew-functional-focus-400"
        ),
        onClick: () => r(e[o - 2]),
        type: "button",
        children: /* @__PURE__ */ n(_e, { className: "size-6 stroke-1" })
      }
    ),
    /* @__PURE__ */ n("ul", { className: "m-0 flex h-8 flex-1 list-none items-center gap-2 text-ellipsis whitespace-nowrap", children: e.map((s, a) => /* @__PURE__ */ l(
      "li",
      {
        className: "group m-0 hidden items-center gap-2 p-0 last:flex lg:flex",
        children: [
          a === o - 1 ? /* @__PURE__ */ n(
            "span",
            {
              className: "text-[22px] font-bold text-brand-primary lg:text-[16px] lg:text-black",
              title: s.name,
              "data-testid": "header-navigation-breadcrumb",
              children: s.name
            }
          ) : /* @__PURE__ */ n(
            "a",
            {
              className: D(
                "rounded-lg text-inherit underline hover:text-brand-primary",
                "outline-none ring-offset-1 focus-visible:ring-3 focus-visible:ring-ignew-functional-focus-400"
              ),
              href: s.url,
              onClick: (d) => {
                d.preventDefault(), r(s);
              },
              children: s.name
            }
          ),
          /* @__PURE__ */ n(ne, { className: "size-4 group-last:hidden" })
        ]
      },
      s.uid
    )) }),
    t && /* @__PURE__ */ n(
      "button",
      {
        "data-testid": "header-navigation-close-button",
        className: D(
          "grid size-8 cursor-pointer place-items-center rounded-full text-brand-primary",
          "outline-none ring-offset-1 focus-visible:ring-3 focus-visible:ring-ignew-functional-focus-400"
        ),
        type: "button",
        onClick: t,
        "aria-label": i("close"),
        children: /* @__PURE__ */ n(Ue, { className: "size-6 gamma:stroke-2 karwei:stroke-1" })
      }
    )
  ] });
}
function W({
  onClose: e,
  onSelect: r,
  breadcrumbs: t,
  categories: i,
  onBack: o,
  lastBreadcrumb: s
}) {
  return /* @__PURE__ */ l("nav", { className: "overflow-hidden", children: [
    /* @__PURE__ */ n(
      He,
      {
        onBack: o,
        breadcrumbs: t,
        onClose: e
      }
    ),
    /* @__PURE__ */ n(Pe, { categories: i ?? [], onSelect: r }),
    s.type === "category" && /* @__PURE__ */ n(Qe, { lastBreadcrumb: s })
  ] });
}
function We({
  menuItem: e,
  store: r
}) {
  const t = F(), { t: i } = f("ig-header");
  if (e.type === "category")
    return [];
  if (e.uid === "root-mobile") {
    const o = [
      {
        uid: "showall",
        type: "menu-item",
        name: i("ShowEntireCatalog"),
        url: i("/catalog"),
        hasSubCategories: !1,
        divider: !0
      },
      {
        uid: "advice",
        type: "menu-item",
        name: i("Advice"),
        url: i("/advice"),
        hasSubCategories: !1
      },
      {
        uid: "services",
        type: "menu-item",
        name: i("ServicesMobile"),
        url: i("/services"),
        hasSubCategories: !1
      },
      {
        uid: "promotions",
        type: "menu-item",
        name: i("Promotions"),
        url: i("/promotions"),
        hasSubCategories: !1
      }
    ];
    return t.name === "karwei_nl" && o.push({
      uid: "inspiration",
      type: "menu-item",
      name: i("Inspiration"),
      url: i("/inspiration"),
      hasSubCategories: !1
    }), o.push({
      uid: "store",
      name: r != null && r.name ? r.name : i("Stores"),
      url: i("/stores"),
      hasSubCategories: !1,
      type: "menu-item",
      icon: "store",
      divider: !0
    }), o;
  }
  return [];
}
function G({
  store: e,
  rootCategory: r
}) {
  const [t, i] = g([r]);
  v(() => {
    i([r]);
  }, [r]);
  const o = t[t.length - 1], s = We({
    menuItem: o,
    store: e
  });
  function a(u) {
    const m = t.findIndex(
      (p) => p.uid === u.uid
    );
    m > -1 && i(t.slice(0, m + 1));
  }
  function d(u) {
    u.hasSubCategories && i([...t, u]);
  }
  return {
    simpleMenuItems: s,
    onBack: a,
    onSelect: d,
    breadcrumbs: t,
    setBreadcrumbs: i,
    lastBreadcrumb: o
  };
}
function Ge() {
  var e;
  (e = document.querySelector("html")) == null || e.classList.add("panel-active");
}
function je() {
  var e;
  (e = document.querySelector("html")) == null || e.classList.remove("panel-active");
}
function Xe({
  categories: e,
  store: r,
  onSelect: t
}) {
  const { t: i } = f("ig-header"), [o, s] = g(!1), [a, d] = g(!1), u = U(null), m = C(
    () => ({
      dataUrl: "categories",
      uid: "root",
      name: i("Catalog"),
      url: i("/catalog"),
      hasSubCategories: !0,
      type: "category"
    }),
    [i]
  ), p = C(
    () => ({
      uid: "root-mobile",
      name: i("Menu"),
      url: "#",
      hasSubCategories: !0,
      type: "menu-item"
    }),
    [i]
  ), {
    breadcrumbs: E,
    lastBreadcrumb: y,
    onBack: w,
    onSelect: x
  } = G({ store: r, rootCategory: m }), {
    breadcrumbs: b,
    lastBreadcrumb: I,
    onBack: le,
    onSelect: ce,
    simpleMenuItems: ue
  } = G({ store: r, rootCategory: p });
  function de(c) {
    c.type === "category" && t(c), ce(c);
  }
  function me(c) {
    le(c), c.uid === "root-mobile" && t(void 0);
  }
  function fe(c) {
    c.type === "category" && t(c), x(c);
  }
  v(() => {
    y.type === "category" && t(y);
  }, [y, t]), v(() => {
    I.type === "category" && t(I);
  }, [I, t]);
  function P(c) {
    c ? Ge() : je(), d(c);
  }
  return /* @__PURE__ */ n(he, { "aria-label": i("MainNavigation"), ref: u, children: /* @__PURE__ */ l(ge, { children: [
    /* @__PURE__ */ l(pe, { children: [
      /* @__PURE__ */ n("div", { className: "contents lg:hidden", children: /* @__PURE__ */ l(
        Ee,
        {
          open: a,
          onOpenChange: (c) => P(c),
          modal: !1,
          children: [
            /* @__PURE__ */ n(
              De,
              {
                asChild: !0,
                "data-testid": "header-navigation-button-mobile",
                children: /* @__PURE__ */ n(be, {})
              }
            ),
            /* @__PURE__ */ l(Ie, { container: u.current, children: [
              /* @__PURE__ */ n("div", { className: "sr-only", children: /* @__PURE__ */ n(Be, { children: i("Menu") }) }),
              /* @__PURE__ */ n(
                W,
                {
                  onClose: () => P(!1),
                  breadcrumbs: b,
                  categories: [...e || [], ...ue],
                  lastBreadcrumb: I,
                  onBack: (c) => me(c),
                  onSelect: (c) => de(c)
                }
              )
            ] }),
            a && /* @__PURE__ */ n("div", { className: "fixed inset-0 z-[9999] bg-[hsl(200deg_60%_12%/75%)] motion-safe:animate-fade-in" })
          ]
        }
      ) }),
      /* @__PURE__ */ n("div", { className: "hidden lg:contents", children: /* @__PURE__ */ l(z, { open: o, onOpenChange: s, children: [
        /* @__PURE__ */ n(
          Y,
          {
            "data-testid": "header-navigation-button-desktop",
            className: h(
              "h-[46px] rounded-lg",
              "outline-none ring-offset-1 focus-visible:ring-3 focus-visible:ring-ignew-functional-focus-400"
            ),
            children: /* @__PURE__ */ n(ye, { isOpen: o })
          }
        ),
        /* @__PURE__ */ n(
          $,
          {
            withBackdrop: !0,
            container: u.current,
            align: "start",
            alignOffset: -121,
            sideOffset: -10,
            children: /* @__PURE__ */ n(
              W,
              {
                onClose: () => s(!1),
                breadcrumbs: E,
                categories: e,
                lastBreadcrumb: y,
                onBack: w,
                onSelect: (c) => fe(c)
              }
            )
          }
        )
      ] }) })
    ] }),
    /* @__PURE__ */ n("div", { className: "hidden lg:contents", children: /* @__PURE__ */ n(ve, {}) })
  ] }) });
}
const re = "base-navigation", Vn = re;
function Je() {
  const [e, r] = g(
    void 0
  ), t = N(), i = C(
    () => te(t),
    [t]
  ), o = Q(
    (u) => i.getCategories(u),
    [i]
  ), s = Q(async () => e ? o(e.dataUrl) : [], [e, o]), { data: a } = L({
    queryKey: [re, e == null ? void 0 : e.uid],
    queryFn: s,
    placeholderData: Ae,
    throwOnError: !1
  });
  function d(u) {
    r(u);
  }
  return { categories: a, onSelect: d };
}
function Zn({ store: e }) {
  const { categories: r, onSelect: t } = Je();
  return /* @__PURE__ */ n(Xe, { store: e, categories: r, onSelect: t });
}
function et({
  className: e,
  children: r,
  ...t
}) {
  return /* @__PURE__ */ n(
    "div",
    {
      className: h(
        "absolute right-0 top-0 flex h-[50px] items-center gap-[10px] pl-[10px] lg:relative lg:h-[46px]",
        e
      ),
      ...t,
      children: r
    }
  );
}
const Ve = O(
  () => import("@intergamma/account/dialog").then((e) => ({
    default: e.Account
  }))
), Ze = O(
  () => import("@intergamma/account/dialog").then((e) => ({
    default: e.LoyaltyBenefits
  }))
);
function nt({
  user: e,
  container: r,
  onLogin: t,
  ...i
}) {
  const { SHOPFRONT_URL: o } = N(), [s, a] = g(!1), [d, u] = g(!1), m = typeof window < "u" && window.location.search.includes(
    "showLoyaltyBenefits"
  );
  document.addEventListener("openAccountDialog", () => {
    a(!0);
  });
  function p() {
    a(!1);
  }
  v(() => {
    const b = document.getElementById("onetrust-consent-sdk");
    b && (b.style.pointerEvents = "auto");
  }, []), v(() => {
    d && a(!0);
  }, [d]), v(() => {
    m && (e != null && e.isLoggedIn) && u(!0);
  }, [m, e]);
  const { isMobile: E } = N(), { t: y } = f("ig-header"), w = E ? Z : ee, x = `${o}/my/`;
  return e != null && e.isLoggedIn ? d ? /* @__PURE__ */ n(
    w,
    {
      trigger: /* @__PURE__ */ n(B, { children: /* @__PURE__ */ n(k, { user: e, href: x }) }),
      open: s,
      onOpenChange: a,
      container: r,
      dialogTitle: y("LoyaltyCard"),
      children: /* @__PURE__ */ n(
        Ze,
        {
          close: () => p(),
          loyaltyCardNumber: e.defaultLoyaltyCardNumber
        }
      )
    }
  ) : /* @__PURE__ */ n(k, { user: e, href: x }) : /* @__PURE__ */ n(
    w,
    {
      trigger: /* @__PURE__ */ n(B, { asChild: !0, children: /* @__PURE__ */ n(k, { user: e }) }),
      open: s,
      onOpenChange: a,
      container: r,
      dialogTitle: y("Login"),
      children: /* @__PURE__ */ n(R, { children: /* @__PURE__ */ n(
        Ve,
        {
          ...i,
          setShowLoyaltyBenefits: u,
          onClose: () => p(),
          onLogin: (b) => t(b).then(() => {
            d || p();
          }),
          initLocation: "global_header"
        }
      ) })
    }
  );
}
const en = O(
  () => import("@intergamma/account/dialog").then((e) => ({
    default: e.Account
  }))
), nn = O(
  () => import("@intergamma/account/dialog").then((e) => ({
    default: e.LoyaltyBenefits
  }))
), j = O(
  () => import("@intergamma/theme-next").then((e) => ({
    default: e.IntergammaTheme
  }))
);
function tt({
  user: e,
  container: r,
  onLogin: t,
  ...i
}) {
  const { SHOPFRONT_URL: o } = N(), [s, a] = g(!1), [d, u] = g(!1), m = ke();
  document.addEventListener("openAccountDialog", () => {
    a(!0);
  });
  function p() {
    a(!1);
  }
  v(() => {
    const b = document.getElementById("onetrust-consent-sdk");
    b && (b.style.pointerEvents = "auto");
  }, []), v(() => {
    d && a(!0);
  }, [d]);
  const { isMobile: E } = N(), { t: y } = f("ig-header"), w = E ? Z : ee, x = `${o}/my/`;
  return e != null && e.isLoggedIn ? d ? /* @__PURE__ */ n(
    w,
    {
      trigger: /* @__PURE__ */ n(B, { children: /* @__PURE__ */ n(k, { user: e, href: x }) }),
      open: s,
      onOpenChange: a,
      container: r,
      dialogTitle: y("LoyaltyCard"),
      children: /* @__PURE__ */ n(j, { formula: m, children: /* @__PURE__ */ n(
        nn,
        {
          close: () => p(),
          loyaltyCardNumber: e.defaultLoyaltyCardNumber
        }
      ) })
    }
  ) : /* @__PURE__ */ n(k, { user: e, href: x }) : /* @__PURE__ */ n(
    w,
    {
      trigger: /* @__PURE__ */ n(B, { asChild: !0, children: /* @__PURE__ */ n(k, { user: e }) }),
      open: s,
      onOpenChange: a,
      container: r,
      dialogTitle: y("Login"),
      children: /* @__PURE__ */ n(R, { children: /* @__PURE__ */ n(j, { formula: m, children: /* @__PURE__ */ n(
        en,
        {
          ...i,
          setShowLoyaltyBenefits: u,
          onClose: () => p(),
          onLogin: (b) => t(b).then(() => {
            d || p();
          }),
          initLocation: "global_header"
        }
      ) }) })
    }
  );
}
function rt() {
  return /* @__PURE__ */ n(X, { children: /* @__PURE__ */ n(q, {}) });
}
function it(e) {
  return /* @__PURE__ */ n(X, { children: /* @__PURE__ */ n(Ye, { ...e }) });
}
const tn = ({ show: e }) => /* @__PURE__ */ n(
  "div",
  {
    className: h(
      "fixed inset-0 z-[-100] size-full bg-black opacity-60 transition-opacity duration-[600ms] ease-in-out",
      e && "z-[9950] block",
      !e && "hidden"
    )
  }
), rn = ({
  show: e
}) => /* @__PURE__ */ n(
  "div",
  {
    className: h(
      "fixed inset-0 z-[-100] size-full bg-white opacity-0 transition-opacity duration-[600ms] ease-in-out",
      e && "z-[9950] opacity-100"
    )
  }
), on = ({
  isSearching: e,
  children: r
}) => /* @__PURE__ */ n(
  "div",
  {
    className: h(
      "absolute left-[-10px] flex w-[calc(100%+20px)] pb-[12px] pl-[110px] pr-[10px] transition-all duration-[400ms] ease-in-out",
      "top-[59px] karwei:top-[65px]",
      e && "top-[10px] z-[9951] pl-[10px] pt-[10px]",
      "lg:bottom-[-12px] lg:top-auto"
    ),
    children: r
  }
);
function M(e, r) {
  return new Promise((t) => {
    const i = e.querySelector(r);
    if (i) {
      t(i);
      return;
    }
    new MutationObserver((o, s) => {
      Array.from(document.querySelectorAll(r)).forEach((a) => {
        t(a), s.disconnect();
      });
    }).observe(e, {
      childList: !0,
      subtree: !0
    });
  });
}
const sn = ({
  searching: e,
  children: r
}) => /* @__PURE__ */ n(
  "div",
  {
    className: h(
      "w-full",
      "md:ease md:absolute md:inset-x-auto md:top-[57px] md:z-[11] md:w-[200px] md:transition-[right] md:duration-[100ms]",
      e && "md:z-[9951]",
      "lg:relative lg:right-auto lg:top-0 lg:ml-8",
      "xl:w-[376px]"
    ),
    children: r
  }
);
function ot() {
  const [e, r] = g(!1), t = U(null);
  return v(() => {
    const i = t.current, o = () => r(!0), s = () => r(!1);
    return i && M(i, "input[type=search]").then((a) => {
      a.addEventListener("focus", o), a.addEventListener("blur", s);
    }), () => {
      i && M(i, "input[type=search]").then((a) => {
        a.removeEventListener("focus", o), a.removeEventListener("blur", s);
      });
    };
  }, [t]), /* @__PURE__ */ l("div", { className: "contents", ref: t, children: [
    /* @__PURE__ */ n(sn, { searching: e, children: /* @__PURE__ */ n(q, {}) }),
    /* @__PURE__ */ n(tn, { show: e })
  ] });
}
const an = ({
  $isSearching: e,
  onClick: r,
  children: t
}) => /* @__PURE__ */ n(
  "button",
  {
    onClick: r,
    className: h(
      "close-search-mask",
      e ? "flex" : "hidden",
      "ml-2.5 size-[46px] flex-[0_0_46px] flex-col items-center pt-[11px] karwei:pt-[9px]"
    ),
    children: t
  }
), ln = () => /* @__PURE__ */ l(
  "svg",
  {
    xmlns: "http://www.w3.org/2000/svg",
    width: "24",
    height: "24",
    viewBox: "0 0 24 24",
    fill: "none",
    stroke: "currentColor",
    strokeWidth: "2",
    strokeLinecap: "round",
    strokeLinejoin: "round",
    children: [
      /* @__PURE__ */ n("line", { x1: "18", y1: "6", x2: "6", y2: "18" }),
      /* @__PURE__ */ n("line", { x1: "6", y1: "6", x2: "18", y2: "18" })
    ]
  }
);
function st() {
  const [e, r] = g(!1), t = U(null);
  return v(() => {
    const o = t.current, s = () => r(!0);
    return o && M(o, "input[type=search]").then((a) => {
      a.addEventListener("focus", s);
    }), () => {
      o && M(o, "input[type=search]").then((a) => {
        a.removeEventListener("focus", s);
      });
    };
  }, [t]), /* @__PURE__ */ l("div", { className: "contents", ref: t, children: [
    /* @__PURE__ */ l(on, { isSearching: e, children: [
      /* @__PURE__ */ n(q, {}),
      /* @__PURE__ */ n(an, { $isSearching: e, onClick: () => {
        r(!1);
      }, children: /* @__PURE__ */ n(ln, {}) })
    ] }),
    /* @__PURE__ */ n(rn, { show: e })
  ] });
}
function ie() {
  return /* @__PURE__ */ n(Re, { className: "pointer-events-none", size: 16 });
}
function oe() {
  return /* @__PURE__ */ n(Fe, { className: "pointer-events-none", size: 16 });
}
function at({
  alternateURL: e,
  container: r
}) {
  const { t } = f("ig-header"), [i, o] = g(!1);
  return F().name !== "gamma_be" ? null : /* @__PURE__ */ l(z, { open: i, onOpenChange: o, children: [
    /* @__PURE__ */ n(Y, { asChild: !0, children: /* @__PURE__ */ l(
      "button",
      {
        className: D(
          J,
          "h-auto cursor-pointer border-none bg-none"
        ),
        children: [
          /* @__PURE__ */ n("strong", { children: t("Language") }),
          i ? /* @__PURE__ */ n(oe, {}) : /* @__PURE__ */ n(ie, {})
        ]
      }
    ) }),
    /* @__PURE__ */ n(
      $,
      {
        className: "!z-[99999] !w-max",
        align: "end",
        onOpenAutoFocus: cn,
        container: r,
        children: /* @__PURE__ */ n("div", { className: "p-5 text-75/normal", children: /* @__PURE__ */ l("ul", { className: "m-0 list-none p-0", children: [
          /* @__PURE__ */ n(A, { children: /* @__PURE__ */ n(
            S,
            {
              className: "whitespace-nowrap no-underline hover:underline",
              lang: "nl",
              href: (e == null ? void 0 : e.nl) ?? "/nl",
              children: "Nederlands"
            }
          ) }),
          /* @__PURE__ */ n(A, { children: /* @__PURE__ */ n(
            S,
            {
              className: "whitespace-nowrap no-underline hover:underline",
              lang: "fr",
              href: (e == null ? void 0 : e.fr) ?? "/fr",
              children: "Français"
            }
          ) })
        ] }) })
      }
    )
  ] });
}
function cn(e) {
  const r = e.target;
  if (!r)
    return;
  const t = r.querySelector("a");
  t && (e.preventDefault(), t.focus());
}
function K(e) {
  const r = T(e, {
    withCredentials: !0
  });
  return {
    getUser() {
      return r.get("/api/session-data");
    },
    getNearbyStores(t) {
      return r.get(
        `/api/session-data/nearby-stores/${t.uid}`
      );
    },
    async changeStore(t) {
      return r.put(
        `/api/session-data/preferred-store/${t.uid}`
      );
    },
    async logout() {
      const t = await this.getGatewayCSRF();
      return r.post("/gateway/logout", null, {
        headers: {
          "X-IG-CSRF-TOKEN": t
        }
      });
    },
    async login(t) {
      const i = await this.getGatewayCSRF();
      return r.post(
        "/gateway/addcard/login",
        t,
        {
          headers: {
            "X-IG-CSRF-TOKEN": i
          }
        }
      );
    },
    async getGatewayCSRF() {
      return (await r.get("/gateway/session")).csrfToken;
    }
  };
}
function lt() {
  const { MYACCOUNT_URL: e } = N(), r = Oe(), t = C(
    () => K(e),
    [e]
  );
  function i(o) {
    return t.changeStore(o).then(() => {
      document == null || document.body.dispatchEvent(
        new window.CustomEvent("refreshStore", { detail: o })
      ), $e({
        type: "change_store",
        data: {
          user_selected_value: o
        }
      });
    });
  }
  return Le({
    mutationFn: i,
    throwOnError: !1,
    onSuccess: () => {
      r.invalidateQueries();
    }
  });
}
const un = "nearby-store";
function ct(e) {
  const { MYACCOUNT_URL: r } = N(), t = C(
    () => K(r),
    [r]
  );
  function i() {
    return e ? t.getNearbyStores(e) : [];
  }
  return L({
    queryKey: [un, e],
    queryFn: i,
    throwOnError: !1
  });
}
function se({
  className: e,
  children: r,
  ...t
}) {
  return /* @__PURE__ */ n(
    "div",
    {
      className: h(
        "rounded-b-lg p-5 gamma:bg-[#f0f2f6] karwei:bg-[#f1f4f8]",
        e
      ),
      ...t,
      children: r
    }
  );
}
function ae({
  children: e,
  className: r,
  ...t
}) {
  return /* @__PURE__ */ n(
    "h3",
    {
      className: h(
        "mb-5 text-[1.2em] font-bold text-brand-primary",
        r
      ),
      ...t,
      children: e
    }
  );
}
function dn() {
  const { t: e } = f("ig-header");
  return /* @__PURE__ */ l(se, { children: [
    /* @__PURE__ */ n(ae, { children: e("OtherStoresNearby") }),
    /* @__PURE__ */ n("div", { className: "mb-5 flex flex-col last:mb-0", children: /* @__PURE__ */ n("div", { className: "block w-full", children: /* @__PURE__ */ n("div", { className: "h-5 w-full animate-pulse rounded-sm bg-[#ddd]" }) }) }),
    /* @__PURE__ */ n("div", { className: "mb-5 flex flex-col last:mb-0", children: /* @__PURE__ */ n("div", { className: "block w-full", children: /* @__PURE__ */ n("div", { className: "h-5 w-full animate-pulse rounded-sm bg-[#ddd]" }) }) }),
    /* @__PURE__ */ n("div", { className: "mb-5 flex flex-col last:mb-0", children: /* @__PURE__ */ n("div", { className: "block w-full", children: /* @__PURE__ */ n("div", { className: "h-5 w-full animate-pulse rounded-sm bg-[#ddd]" }) }) }),
    /* @__PURE__ */ l("ul", { className: "m-0 mt-5 list-none p-0", children: [
      /* @__PURE__ */ n(A, { children: /* @__PURE__ */ n(S, { href: e("/stores/search"), children: e("AllStores") }) }),
      /* @__PURE__ */ n(A, { children: /* @__PURE__ */ n(S, { href: e("/stores/holidays"), children: e("SpecialOpeningHours") }) })
    ] })
  ] });
}
function mn({ nearbyStore: e, onSaveStore: r }) {
  const { t } = f("ig-header");
  return /* @__PURE__ */ l("div", { className: "mb-5 grid grid-cols-2 last:mb-0", children: [
    /* @__PURE__ */ n("div", { className: "flex items-center", children: /* @__PURE__ */ n(
      "a",
      {
        className: "font-bold text-black no-underline hover:underline",
        href: t("/stores/details", e),
        children: e.name
      }
    ) }),
    /* @__PURE__ */ n("div", { className: "flex items-center", children: /* @__PURE__ */ l(
      "button",
      {
        className: "group flex h-auto cursor-pointer items-center border-none bg-transparent p-0 text-[1em] hover:underline",
        onClick: () => r(e),
        children: [
          /* @__PURE__ */ n(ze, { className: "mr-[5px] rounded-[20px] bg-white !p-[5px] group-hover:fill-current gamma:text-brand-primary karwei:text-[#111316]" }),
          t("SaveAsMyStore")
        ]
      }
    ) })
  ] });
}
function fn({ nearbyStores: e, onSaveStore: r }) {
  const { t } = f("ig-header");
  return /* @__PURE__ */ l(se, { children: [
    /* @__PURE__ */ n(ae, { children: t("OtherStoresNearby") }),
    e.map((i) => /* @__PURE__ */ n(
      mn,
      {
        nearbyStore: i,
        onSaveStore: r
      },
      i.uid
    )),
    /* @__PURE__ */ l("ul", { className: "m-0 mt-5 list-none p-0", children: [
      /* @__PURE__ */ n(A, { children: /* @__PURE__ */ n(S, { href: t("/stores/search"), children: t("AllStores") }) }),
      /* @__PURE__ */ n(A, { children: /* @__PURE__ */ n(S, { href: t("/stores/holidays"), children: t("SpecialOpeningHours") }) })
    ] })
  ] });
}
const hn = [
  "SUNDAY",
  "MONDAY",
  "TUESDAY",
  "WEDNESDAY",
  "THURSDAY",
  "FRIDAY",
  "SATURDAY"
];
function gn({ store: e }) {
  const { t: r } = f("ig-header");
  return /* @__PURE__ */ l("div", { className: "p-5", children: [
    /* @__PURE__ */ n("h2", { className: "mb-5 text-[1.3em] font-bold text-brand-primary", children: e.name }),
    /* @__PURE__ */ l("div", { className: "mb-5 flex last:mb-0", children: [
      /* @__PURE__ */ n("div", { className: "flex-1 basis-1/2", children: /* @__PURE__ */ l("div", { className: "leading-normal", children: [
        e.address.street,
        " ",
        e.address.streetNumber,
        e.address.apartment ? `/${e.address.apartment}` : null,
        /* @__PURE__ */ n("br", {}),
        e.address.zipCode,
        " ",
        e.address.city,
        /* @__PURE__ */ n("br", {}),
        e.address.phone,
        /* @__PURE__ */ n("br", {}),
        /* @__PURE__ */ n("br", {}),
        /* @__PURE__ */ n(S, { href: r("/stores/details", { slug: e.slug }), children: r("ShowOnMap") })
      ] }) }),
      /* @__PURE__ */ n("div", { className: "flex-1 basis-1/2", children: e.openingHours.map((t) => /* @__PURE__ */ l("div", { className: "mb-5 flex last:mb-0", children: [
        /* @__PURE__ */ n("div", { className: "flex-1 basis-1/2", children: r(hn[t.dayOfWeek]) }),
        /* @__PURE__ */ n("div", { className: "flex-1 basis-1/2", children: t.opens && t.closes ? r("Timespan", { opens: t.opens, closes: t.closes }) : r("Closed") })
      ] }, t.dayOfWeek)) })
    ] })
  ] });
}
function pn({
  store: e,
  isLoadingNearbyStores: r,
  ...t
}) {
  return /* @__PURE__ */ l("div", { className: "w-[500px] font-primary text-75/normal tabular-nums", children: [
    /* @__PURE__ */ n(gn, { store: e }),
    r ? /* @__PURE__ */ n(dn, {}) : /* @__PURE__ */ n(fn, { ...t })
  ] });
}
function bn() {
  const { t: e } = f("ig-header");
  return /* @__PURE__ */ n(V, { href: e("/stores/search"), children: /* @__PURE__ */ n("strong", { children: e("Stores") }) });
}
function ut({
  store: e,
  isLoadingNearbyStores: r,
  container: t,
  ...i
}) {
  const [o, s] = g(!1);
  return e ? /* @__PURE__ */ l(z, { open: o, onOpenChange: s, children: [
    /* @__PURE__ */ l(Y, { className: J, children: [
      /* @__PURE__ */ n("strong", { children: e.name }),
      o ? /* @__PURE__ */ n(oe, {}) : /* @__PURE__ */ n(ie, {})
    ] }),
    /* @__PURE__ */ n(
      $,
      {
        className: "z-[999999] !max-w-[500px]",
        align: "end",
        container: t,
        children: /* @__PURE__ */ n(
          pn,
          {
            ...i,
            store: e,
            isLoadingNearbyStores: r
          }
        )
      }
    )
  ] }) : /* @__PURE__ */ n(bn, {});
}
function dt() {
  const { t: e } = f("ig-header");
  return /* @__PURE__ */ n(V, { href: e("/stores/search"), children: /* @__PURE__ */ n(Ne, { className: "w-[120px]" }) });
}
function mt({ children: e }) {
  const { t: r } = f("ig-header");
  return /* @__PURE__ */ n(
    "nav",
    {
      className: h(
        "absolute right-[220px] top-0 hidden h-[46px] items-center",
        "lg:right-0 lg:flex lg:h-[46px]"
      ),
      "aria-label": r("UserNavigation"),
      children: /* @__PURE__ */ n("ul", { className: "m-0 flex list-none items-center gap-[25px] p-0", children: e && we.map(e, (t) => xe(t) ? /* @__PURE__ */ n("li", { children: Ce(t) }) : null) })
    }
  );
}
const yn = O(
  () => import("./Alert-CxevVYNO.js").then((e) => ({
    default: e.Alert
  }))
);
function vn({ alerts: e, onDismissAlert: r }) {
  return /* @__PURE__ */ n(R, { fallback: null, children: e.map(({ sys: t, fields: i }) => /* @__PURE__ */ n(
    yn,
    {
      label: i.description,
      id: t.id,
      onDismissAlert: r
    },
    t.id
  )) });
}
function Nn({ alerts: e, onDismissAlert: r }) {
  return /* @__PURE__ */ n(Se, { children: /* @__PURE__ */ n(vn, { alerts: e, onDismissAlert: r }) });
}
const _ = "disabledAlerts";
function Sn() {
  if (typeof sessionStorage > "u")
    return [];
  const e = sessionStorage.getItem(_);
  if (!e)
    return [];
  try {
    return JSON.parse(e);
  } catch {
    return [];
  }
}
function wn(e) {
  if (typeof sessionStorage > "u")
    return;
  const r = sessionStorage.getItem(_) || "[]";
  try {
    const t = JSON.parse(r);
    t.includes(e) || (t.push(e), sessionStorage.setItem(_, JSON.stringify(t)));
  } catch {
  }
}
const xn = T("https://cdn.contentful.com/spaces/");
function Cn({
  contentfulSpaceId: e,
  contentfulToken: r,
  contentfulLocale: t
}) {
  const i = "alert", o = r;
  return xn.get(
    `${e}/environments/master/entries?content_type=${i}&access_token=${o}&locale=${t}`
  ).then((s) => {
    var a;
    return ((a = s == null ? void 0 : s.items) == null ? void 0 : a.length) > 0 ? s.items : [];
  });
}
const kn = "alerts";
function An() {
  var u;
  const [e, r] = g(Sn), { contentfulSpaceId: t, contentfulToken: i } = F(), { i18n: o } = f(), s = o.language.substring(0, 2) === "fr" ? "fr" : "nl", a = L({
    queryKey: [kn],
    queryFn: () => Cn({
      contentfulLocale: s,
      contentfulSpaceId: t,
      contentfulToken: i
    }),
    throwOnError: !1
  });
  function d(m) {
    wn(m), r([...e, m]);
  }
  return {
    alerts: ((u = a.data) == null ? void 0 : u.filter(
      (m) => !e.includes(m.sys.id)
    )) ?? [],
    onDismissAlert: d
  };
}
function ft() {
  const { alerts: e, onDismissAlert: r } = An();
  return /* @__PURE__ */ n(Nn, { alerts: e, onDismissAlert: r });
}
function On(e) {
  const r = T(e, {
    withCredentials: !0
  });
  return {
    async getNumberOfProducts() {
      const t = await r.get(
        "/resources/cart/numberOfProducts"
      );
      return Number.isNaN(t) ? 0 : t;
    }
  };
}
const Ln = "number-of-products";
function ht() {
  const { CHECKOUT_URL: e } = N(), r = C(
    () => On(e),
    [e]
  );
  function t() {
    return r.getNumberOfProducts();
  }
  return L({
    queryKey: [Ln],
    queryFn: t,
    throwOnError: !1
  });
}
const En = "wishlist";
function Dn() {
  var r;
  if (typeof H > "u")
    return 0;
  const e = H.getItem(En);
  return e ? (r = JSON.parse(e)) == null ? void 0 : r.length : 0;
}
const In = "number-of-favorites";
function gt() {
  return L({
    queryKey: [In],
    queryFn: Dn,
    throwOnError: !1,
    initialData: 0
  });
}
const Bn = "user";
function pt() {
  const { MYACCOUNT_URL: e } = N(), r = C(
    () => K(e),
    [e]
  );
  return L({
    queryKey: [Bn],
    queryFn: r.getUser,
    throwOnError: !1
  });
}
function bt(e) {
  return te(e).getCategories;
}
export {
  kn as ALERTS_QUERY_KEY,
  re as BASE_NAVIGATION_QUERY_KEY,
  Xe as BaseNavigation,
  Nt as CartIconNavigation,
  St as FavoritesIconNavigation,
  Nn as HeaderAlerts,
  wt as HeaderContainer,
  xt as IconsNavigation,
  et as IconsNavigationContainer,
  at as LanguageSelector,
  Ct as LoadingBaseNavigation,
  kt as LoadingHeader,
  At as LoadingIconsNavigation,
  Ot as LoadingSearch,
  dt as LoadingStores,
  Lt as LoadingTopNavigation,
  Zn as MainBaseNavigation,
  ft as MainHeaderAlerts,
  rt as MainSearch,
  un as NEARBY_STORES_QUERY_KEY,
  In as NUMBER_OF_FAVORITES_QUERY_KEY,
  Vn as QUERY_KEY,
  it as Search,
  X as SearchContainer,
  tn as ShopfrontPageMask,
  rn as ShopfrontSearchBarMask,
  on as ShopfrontSearchContainer,
  ot as ShopfrontSearchDesktop,
  st as ShopfrontSearchMobile,
  ut as StoreLocatorDropdown,
  tt as StyledUserIconNavigation,
  mt as TopNavigation,
  Bn as USER_QUERY_KEY,
  nt as UserIconNavigation,
  k as UserIconNavigationButton,
  bt as createGetAssortimentCategories,
  Ge as disablePageScroll,
  je as enablePageScroll,
  Cn as getAlerts,
  Sn as getSessionDisabledAlert,
  wn as setSessionDisabledAlert,
  An as useAlertsQuery,
  Je as useCategoriesQuery,
  lt as useChangeStoreMutation,
  ct as useNearbyStoresQuery,
  gt as useNumberOfFavorites,
  ht as useNumberOfProductsQuery,
  Dt as useTranslation,
  pt as useUserQuery
};
