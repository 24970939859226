// TODO: Move redirects to Hosting instead of client side redirects
export const gammaNlRedirects = {
  "/mijn-gegevens": "/mijn-gegevens/persoonlijke-gegevens",
  "/personal-information": "/mijn-gegevens/persoonlijke-gegevens",
  "/preferences": "/mijn-gegevens/nieuwsbrief",
  "/change-email": "/mijn-gegevens/e-mailadres-wijzigen",
  "/change-password": "/mijn-gegevens/wachtwoord-wijzigen",
  "/loyaltycards":
    "/mijn-gegevens/gamma-voordeelpas of /mijn-gegevens/club-karwei-kaart",
  "/orders": "/mijn-bestellingen",
  "/remove-account": "/verzoek-tot-verwijderen-account",
  "/loyaltyMutations": "/loyalty-transactie-overzicht",
  "/orderstatus": "/bestelstatus-opvragen",
  "/logout": "/uitloggen",
  "/register": "/account-aanmaken",
  "/forgot-password": "/nieuw-wachtwoord-aanvragen",
  "/password-recovery": "/nieuw-wachtwoord-aanvragen/verificatie",
  "/register-loyalty-card": "/gamma-voordeelpas/registreren",
  "/register-loyalty-card/activate": "/gamma-voordeelpas/activeer",
  "/giftcard": "/cadeaupas",
  "/request-return": "/aanmelden-retour",
  "/newsletter/subscribe": "/nieuwsbrief",
  "/service-and-contact/contact": "/service-en-contact/contact",
  "/success/register": "/account-aanmaken-bevestigd",
  "/service-en-contact": "/service-en-contact/contact",
  "/login": "/inloggen",
  "/confirm-email": "/e-mailadres-bevestigen",
  "/profile": "/mijn-gegevens/persoonlijke-gegevens",
  "/profile/edit/email": "/mijn-gegevens/e-mailadres-wijzigen",
  "/profile/edit/newsletters": "/mijn-gegevens/nieuwsbrief",
  "/profile/edit/personal_info": "/mijn-gegevens/persoonlijke-gegevens",
  "/profile/edit/password": "/mijn-gegevens/wachtwoord-wijzigen",
  "/profile/edit/loyaltycards": "/mijn-gegevens/gamma-voordeelpas",
  "/password_recovery": "/nieuw-wachtwoord-aanvragen/verificatie",
  "/signup": "/account-aanmaken",
  "/order": "/bestelstatus-opvragen",
  "/return": "/aanmelden-retour",
  "/giftcard/showBalance": "/cadeaupas",
  "/verificatie-nieuwsbrief-aanvraag": "/verificatie/newsletter",
  "/gamma-voordeelpas": "/mijn-gegevens/gamma-voordeelpas",
  "/purchases": "/mijn-aankopen",
};
