function r(a, e) {
  if (a.length < 2)
    throw new Error(`Could not get allocation for ${a}`);
  const o = 1 / a.length * e * 100, n = 1 / a.length * (e + 1) * 100;
  return [o, n];
}
function t(a) {
  const e = {};
  if (!a.includes("A-control"))
    throw Error("No control group found with name 'A-control'");
  for (const [o, n] of a.entries())
    e[n] = {
      allocation: r(a, o)
    };
  return e;
}
const _ = {
  // CHECKOUT ------------------------------------------------------------------
  "abtest_drl_3238-dba_3": {
    scope: "local",
    description: "Remove promise maatwerk",
    variants: t(["A-control", "B-notext"]),
    domain_and_index: "abtest_name_dba_3",
    formula: {
      gamma_nl: !0,
      gamma_be: !0,
      karwei_nl: !0
    }
  },
  "abtest_drl_2997-dba_1": {
    scope: "local",
    description: "Move kortingscode",
    variants: t(["A-control", "B-above", "C-outside"]),
    domain_and_index: "abtest_name_dba_1",
    formula: {
      gamma_nl: !0,
      gamma_be: !0,
      karwei_nl: !0
    }
  },
  "abtest_dba_2980-dba_5": {
    scope: "local",
    description: "New checkout summary design",
    variants: t(["A-control", "B-new"]),
    domain_and_index: "abtest_name_dba_5",
    formula: {
      gamma_nl: !0,
      gamma_be: !0,
      karwei_nl: !0
    }
  },
  "abtest_dba_3091-dba_2": {
    scope: "local",
    description: "Show payment options in cart",
    variants: t(["A-control", "B-options"]),
    domain_and_index: "abtest_name_dba_2",
    formula: {
      gamma_nl: !0,
      gamma_be: !0,
      karwei_nl: !0
    }
  },
  // MYACCOUNT -----------------------------------------------------------------
  "abtest_drl_2343-drl_1": {
    scope: "local",
    description: "Reward commercial optin for NEW accounts with 500 loyalty points",
    variants: t(["A-control", "B-new"]),
    domain_and_index: "abtest_name_drl_1",
    formula: {
      gamma_nl: !0,
      gamma_be: !1,
      karwei_nl: !1
    }
  },
  "abtest_drl_2846-drl_2": {
    scope: "local",
    description: "Login via code as default",
    variants: t(["A-control", "B-new"]),
    domain_and_index: "abtest_name_drl_2",
    formula: {
      gamma_nl: !0,
      gamma_be: !0,
      karwei_nl: !0
    }
  },
  // SHOPFRONT -----------------------------------------------------------------
  "abtest_xtd_autosug-ew_4": {
    scope: "local",
    description: "Extend autosuggest/autocomplete with categories filter",
    variants: t(["A-control", "B-six-sug", "C-incl-cat"]),
    domain_and_index: "abtest_name_ew_4",
    formula: {
      gamma_nl: !0,
      gamma_be: !0,
      karwei_nl: !0
    }
  }
};
export {
  _ as experiments
};
