/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { cn } from "@intergamma/common";
import { Drawer } from "@intergamma/dialog";
import { Cross } from "@intergamma/ui";

import { useTranslation } from "react-i18next";

import { useDeviceContext } from "~/components/Device";

interface SideBarProps {
  title: string;
  show: boolean;
  close: () => void;
  children: React.ReactNode;
}

export function SideBar({ title, close, show, children }: SideBarProps) {
  const { t } = useTranslation("ig-header");
  const { type } = useDeviceContext();

  return (
    <Drawer open={show} onOpenChange={close} animationDirection="rtl">
      <div
        className={cn(
          "flex border-b-[1px] px-6 py-8 gamma:border-[#a1b5c5] karwei:border-[#dce1e8]",
          type === "kiosk" && "mt-20",
        )}
        data-testid="sidebar"
      >
        <h2 className="w-4/5 text-400">{title}</h2>
        <div className="flex w-1/5 justify-end">
          <button type="button" onClick={close}>
            <span className="sr-only">{t("close")}</span>
            <Cross />
          </button>
        </div>
      </div>

      <div className="p-6">{children}</div>
    </Drawer>
  );
}
