import { jsx as a } from "react/jsx-runtime";
import { lazy as e, Suspense as l } from "react";
import { useTheme as i } from "styled-components";
const o = e(
  () => import("./GAMMA_BE-j4lxpG6Z.js").then((t) => ({
    default: t.Card
  }))
), m = e(
  () => import("./GAMMA_NL-BdkUcfN5.js").then((t) => ({
    default: t.Card
  }))
), u = e(
  () => import("./KARWEI-Cv-HmdIU.js").then((t) => ({
    default: t.Card
  }))
);
function s({ cardNumber: t, language: r }) {
  const { name: n } = i();
  if (!t)
    return null;
  switch (n) {
    case "gamma_be":
      return /* @__PURE__ */ a(o, { cardNumber: t, language: r });
    case "gamma_nl":
      return /* @__PURE__ */ a(m, { cardNumber: t });
    case "karwei_nl":
      return /* @__PURE__ */ a(
        u,
        {
          cardNumber: t,
          title: "Digitale Club Karwei-kaart"
        }
      );
    default:
      return null;
  }
}
function C({ cardNumber: t, language: r }) {
  return /* @__PURE__ */ a(l, { fallback: null, children: /* @__PURE__ */ a(s, { cardNumber: t, language: r }) });
}
export {
  C as LoyaltyCard
};
