import { useEffect, useState } from "react";

import { dispatchAdobeEvent } from "@intergamma/adobe-tracking";
import { getDomain } from "@intergamma/common";

import Cookies from "js-cookie";

import { useMyAccountConfig } from "~/config/MyAccountConfigContext";

import { useSessionData } from "../SessionDataContext";

const TRACKING_COOKIE_NAME = "sendIsLoggedInEvent";

export function useTrackingLoggedIn() {
  const sessionData = useSessionData();
  const { baseConfig } = useMyAccountConfig();
  const [isLoggedInEventAlreadySent, setIsLoggedInEventAlreadySent] = useState(
    Cookies.get(TRACKING_COOKIE_NAME) === "true",
  );

  useEffect(() => {
    // Check for falsy values (true string will not pass)
    if (!isLoggedInEventAlreadySent && sessionData.isLoggedIn) {
      dispatchAdobeEvent({
        type: "is_logged_in",
        data: {
          kdbid: sessionData.uid,
        },
      });

      setIsLoggedInEventAlreadySent(true);
      Cookies.set(TRACKING_COOKIE_NAME, String(true), {
        domain: getDomain(baseConfig.fullBrand),
      });
    }

    if (!sessionData.isLoggedIn) {
      Cookies.set(TRACKING_COOKIE_NAME, String(false), {
        domain: getDomain(baseConfig.fullBrand),
      });
    }
  }, [
    isLoggedInEventAlreadySent,
    sessionData.isLoggedIn,
    sessionData.uid,
    baseConfig.fullBrand,
  ]);
}
