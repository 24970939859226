import { useTranslation } from "react-i18next";
import { Navigate, useLocation, useSearchParams } from "react-router-dom";

import { getLanguage } from "~/helpers/getLanguage";

export function LanguageRedirect() {
  const { i18n } = useTranslation();
  const language = getLanguage(i18n.language);

  const { pathname } = useLocation();
  const path = pathname.replace(/\/my\/?/, "/");

  const [searchParams] = useSearchParams();
  const searchParamsString =
    [...searchParams.values()].length > 0 ? `?${searchParams.toString()}` : "";

  return <Navigate to={`${language}${path}${searchParamsString}`} replace />;
}
