import { NonIndexRouteObject } from "react-router-dom";

export const routeMapper = (
  country: "nl" | "be",
  route: NonIndexRouteObject,
) => {
  if (country !== "be") {
    return route;
  }

  return {
    ...route,
    path: route?.path?.substring(7),
  };
};
